import React, { useState , useEffect} from 'react';
import { Link, withRouter , useHistory} from "react-router-dom";

import {useForm, useStep } from 'react-hooks-helper'
import {Form, useFormik}  from 'formik'
import * as Yup from 'yup';
import  Container from '@material-ui/core/Container'
import FormLabel from '@material-ui/core/FormLabel'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Typography from '@material-ui/core/Typography'; 
import { makeStyles } from '@material-ui/core/styles';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetail from '@material-ui/core/AccordionDetails'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Layout from '../core/Layout';
import { sendComment, sendDocumentsRequest } from '../util';
import { TextareaAutosize, TextField } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import Checkbox from '@material-ui/core/Checkbox';

const Results = ({application}) =>{  
    const [display,setDisplay] = useState('comment')
    const [selectedField, selecField] = useState([]);
 
    const text =  `Dear ${application['First Name']}  ${application['Last Name']}\n \n\n\n\nlink \n We thank you for your co-operation. \n Was Salaam`
    
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; 

    
    const upload = [ 'ID Document','Proof of Income','Testimonial Letter','Motivational Letter','Acceptance Letter',
                     'Result', 'Proof of Registration', 'Fees Statement' ]
    const validationSchema = Yup.object({
        email: Yup.string().required('Enter your Eamil'),
        comment: Yup.string().required('enter comment')
    })
    
    const {handleSubmit, handleChange, handleBlur, setValues,  values, touched, errors } = useFormik({ 
        initialValues: {
            comment:'',
            to: application['Email'],
            cc:'',
            subject: 'Request for outstanding documnets 2022',
            field: [],
            body: 'text',
            loading: false,
            redirectToReferrer: false
        },
        validationSchema
    });
    const { comment, cc, to,subject, body,field, loading } = values;	


    const  notEmpty = (obj) => {
        
        for (var key in obj) {
            if (obj[key] == ""){
                alert(`Select File for ${key}`)
                return false;
            }
        }
        return true;
    }
    const  isError = obj => {
        for (var key in obj) {
            if (obj[key])
                return obj[key];
        }
        return false;
    }
    

    const noError = (data, err )=>{

     /*  if (notEmpty(data))  {         
            setValues({ ...values, error: `Enter Regisration Data`, success: false });
            return false 
        } */
        if (isError(err)) {
            setValues({ ...values, error: isError(err) , success: false });
            return  false       
        }
        setValues({...values, error: '' });
        return true;
        //setValues({ ...values, error: false  });

    }
    const saveComment = ()=> {
        //setValues({ ...values, error: false, loading: true });
        sendComment( comment, application['application_id'] ).then(data => {
            if (data.error) {
                alert(data.error)
            } else {
             alert('Comment Save')
            }
        });
    }

    const AddField = ()=>{
        return (
            <Container >
                {showLoading()}
                <div style={{ color: 'red', fontSize: 20 }} >
                    
                </div>
                <div className="form-group">
                    <label className="form-label">Select Document</label>
                </div>
                <div className="form-group">
                    <div>
                        <select id="decument" name="decument" onBlur={handleBlur} onChange={handleAddField} type="option" className="form-select"> 
                            <option  selected hidden >select document</option>
                            <option value="0" >ID Document</option> 
                            <option value="1" >Proof of Income</option>
                            <option value="2" >Testimonial Letter</option>  
                            <option value="3" >Motivational Letter</option>
                            <option value="4">Acceptance Letter</option>
                            <option value="5" >Result</option>
                            <option value="6">Proof of Registration</option>
                            <option value="7" >Fees Statement</option>                
                        </select>
                        { touched.gender && errors.gender ? <div  className="alert alert-danger"> { errors.gender } </div>: null}
                    </div>                  
                </div>
                <div>  { selectedField.length > 0 && ( <div className="alert alert-secondary" role="alert"> <ul>   {selectedField.map((item, i) => { return  <span><p> {item} <button  id={item} name={item} onClick={removeField}/></p></span>;
                                             })}
                                           </ul>
                                              </div>  )}  
                              </div>
             </Container  >
        )
    }
    const handleAddField =(event)=>{
        console.log(upload[parseInt(event.target.value)])
        if (!selectedField.includes(upload[parseInt(event.target.value)])) {   
            selecField((prevMessage) => ([
                ...prevMessage, upload[parseInt(event.target.value)]
                
            ]));
            const newarr = [...field];
            newarr.push(event.target.value);
            setValues({...values, field: newarr});
           
        }
        else{
            alert(`${upload[parseInt(event.target.value)]} is already selected`)
        }

    }

    const removeField = (event) =>{
        event.preventDefault()
        selecField(selectedField.filter(item => item !== event.target.name));
        const newarr = [...field];
        newarr.pop(event.target.value);
        setValues({...values, field: newarr});
    }
    const EmailForm = ()=>{
        return (
            <Container>
                    <div className="form-group">
                        <label className="form-label">to</label>
                    </div>
                    <div className="form-group">
                        <Checkbox key="acceptanceEmails" id="acceptanceEmails" name="acceptanceEmails" onBlur={handleBlur} onChange={handleChange} value='1'   /> 
                        <label className="form-label"> Send to All Accepted Applicants </label>
                        { touched.email && errors.email ? <div  className="alert alert-danger"> { errors.email} </div> : null}                    
                    </div>
                    <div>     
                    </div>
                    <div className="form-group">
                        <label className="form-label">TO</label>                                            
                         <input className="form-control" id="cc" name="cc"  onBlur={handleBlur} onChange={handleChange} type="text" value={to}  /> 
                        { touched.to && errors.to ? <div  className="alert alert-danger"> { errors.to} </div> : null}                        
                    </div>
                    <div className="form-group">
                        <label className="form-label">cc</label>                                            
                         <input className="form-control" id="cc" name="cc"  onBlur={handleBlur} onChange={handleChange} type="text" value={cc}  /> 
                        { touched.cc && errors.cc ? <div  className="alert alert-danger"> { errors.cc} </div> : null}                        
                    </div>
                    <div className="form-group">
                        <label className="form-label">subject</label>                                            
                         <input className="form-control" id="subject" name="subject"  onBlur={handleBlur} onChange={handleChange} type="text" value={subject}  /> 
                        { touched.subject && errors.subject ? <div  className="alert alert-danger"> { errors.subject} </div> : null}                        
                    </div>
                    <div className="form-group">
                        <label className="form-label">body</label>                      
                        <textarea id="body" name="body" rows="15" onBlur={handleBlur} onChange={handleChange} type="text" className="form-control" value={body}/>
                        {touched.body && errors.body ? <div  className="alert alert-danger"> { errors.body } </div> : null}                 
                    </div>
                    <div> 
                        <input id="mail" type="button" value="Send" onClick={() => sendEmail() } style={{marginleft: 10}} className="btn-success form-control btn-next" style={{marginTop: 10}}/>                            
                    
                    
                    
                    </div>
            </Container>
        )
    }

    const showNotes = () =>{
        return (<div> 
       { application.review.map(item => (         
             <div> { (item['Comment Date']).substring(0,10)}   { item['Comment']}</div>   
        ))} 
    </div> )
    }
     
    const showLoading = () =>
        loading && (
            <div className="alert alert-info">
                <h2>sending...</h2>
            </div>
        );

    const DisplayForm = () =>{
        switch(display){
            case 'comment':
                return Comment()
            case 'emailform':
                return EmailForm()
        }
    }
    const sendEmail = ()=> {
        window.scrollTo(0, 0);
        setValues({ ...values, error: false, loading: true });
        let emai = [to]  
        sendDocumentsRequest( {to,cc,subject,field, applicant_id: application['applicant_id'], body} ).then(data => {
                    if (data.error) {
                        setValues({ ...values, error: false, loading: false });
                        alert(data.error)
                    } else {
                    setValues({ ...values, error: false, loading: false });
                    console.log(data.success)
                    alert(`No application found for these emails: \n ${data.success}`)
                    }
                })
         }

    return(
        <div>
            <Container maxWidth="sm">

            <form>

                {AddField()}
                {EmailForm()}                 
            </form>
            </Container>
        </div>
    )
    
}
export default Results;