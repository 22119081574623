import React, { useState , useEffect} from 'react';
import { Link, withRouter , useHistory} from "react-router-dom";

import {useForm, useStep } from 'react-hooks-helper'
import {Form, useFormik}  from 'formik'
import * as Yup from 'yup';
import  Container from '@material-ui/core/Container'
import FormLabel from '@material-ui/core/FormLabel'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Typography from '@material-ui/core/Typography'; 
import { makeStyles } from '@material-ui/core/styles';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetail from '@material-ui/core/AccordionDetails'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Layout from '../core/Layout';
import { getResultsData,getAllResults, sendComment, sendResultsRequest } from '../util';
import { TextareaAutosize, TextField } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import Checkbox from '@material-ui/core/Checkbox';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const ResultsRequest = ({application}) =>{  
    const [display,setDisplay] = useState('comment')
    const [selectedField, selecField] = useState([]);
    const [selectedEmail, selecEmail] = useState([]);
    const text =  'Dear first_name  last_name\n \n\n \n \n \n Click on the link below or copy and paste it into a browser address bar \n \n url \n \n \n We thank you for your co-operation. \n Was Salaam'
  
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; 
    const upload = [ 'Med-year results', 'Final year resulls' ]
    const validationSchema = Yup.object({
        email: Yup.string().required('Enter your Eamil'),
        comment: Yup.string().required('enter comment')
    })
    
    const {handleSubmit, handleChange, handleBlur, setValues,  values, touched, errors } = useFormik({ 
        initialValues: {
            resultType:'',
            comment:'',
            email:'',
            selectedEmails: [],
            selectedApps: [],
            cc:'',
            bcc:'',
            subject: 'Request for Results 2023',
            field: [],
            body: text,
            acceptedEmailsCheck:false,
            paidEmailsCheck:false ,
            acceptanceEmails: '0',
            application_year: 'all',
            results_period: 'all',
            loading: false,
            redirectToReferrer: false
        },
        validationSchema
    });
    const {application_year,results_period,resultType,comment,cc,selectedApps,selectedEmails, acceptanceEmails,acceptedEmails,acceptedEmailsCheck,
           paidEmailsCheck,email,re_email ,subject, body,field,loading,error,redirectToReferrer } = values;	

    const  notEmpty = (obj) =>{        
        for (var key in obj) {
            if (obj[key] == ""){
                alert(`Select File for ${key}`)
                return false;
            }
        }
        return true;
    }
    
    const  isError = obj =>{
        for (var key in obj){
            if (obj[key])
                return obj[key];
        }
        return false;
    }
    
    const noError = (data, err )=>{
        /* if (notEmpty(data))  {         
            setValues({ ...values, error: `Enter Regisration Data`, success: false });
            return false 
        } */
        if (isError(err)) {
            setValues({ ...values, error: isError(err) , success: false });
            return  false       
        }
        setValues({...values, error: '' });
        return true;
        //setValues({ ...values, error: false  });

    }
    const saveComment = ()=> {
        //setValues({ ...values, error: false, loading: true });
        sendComment( comment, application['application_id'] ).then(data => {
            if (data.error) {
                alert(data.error)
            } else {
             alert('Comment Save')
            }
        });
    }

    const exportToXLSX = () => {	
        getAllResults({application_year,results_period}).then(results => {
            const fileName = "results"
            const fileType =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            const fileExtension = ".xlsx";                    
            const ws = XLSX.utils.json_to_sheet(results);
            const wb = { Sheets: { data: ws }, SheetNames: ["data"]};
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        })
    }
    
    const Filter = () => {
        return (		
              <div className="row" style={{ marginLeft: 60 }}>
				<div className="form-group col-xs-10 col-sm-4 col-md-2 col-lg-2" >
                    <div style={{ marginLeft: 0 }}>
                        <label className="form-label">
                            {"Application Year"}
                        </label>{""}
                        <label className="form-label">
                            {""}
                        </label>{""}
                        <select id="application_year" name="application_year" onBlur={handleBlur} onChange={handleChange} type="option" className="form-select" value={application_year}> 
                            <option selected value="all">all</option>
							<option value="2019">2019</option>
							<option value="2020">2020</option>
							<option value="2021">2021</option>
							<option value="2022">2022</option> 
							<option value="2023">2023</option>
							<option value="2024">2024</option>
                        </select>      
                    </div>
                </div>    
                <div className="form-group col-xs-10 col-sm-4 col-md-2 col-lg-2" >
                    <div style={{ marginLeft: 0 }}>
                        <label className="form-label">
                            {"Results Period"}
                        </label>{""}
                        <label className="form-label">
                            {""}
                        </label>{""} 
                        <select id="results_period" name="results_period" onBlur={handleBlur} onChange={handleChange} type="option" className="form-select" value={results_period}> 
                            <option  value="all">all</option>
                            <option  value="finalyear_transcript">Final Year</option>
                            <option  value="midyear_transcript">Mid Year</option>
                        </select>      
                    </div>
                </div>
                <div className="form-group col-xs-10 col-sm-4 col-md-2 col-lg-2" >
                    <input
                        id="searcbutton"
                        type="button"
                        value="Download Results"
                        className="btn-success form-control"                        
                        onClick={() =>{exportToXLSX()}}
                        style={{  marginLeft: 0, width: 150 }}
                    />
                </div>
            </div>
        );
    };
    const AddField = ()=>{
        return (
            <Container>
                {showLoading()}       
                <div className="form-group">
                    <label className="form-label">Select Result Period</label>
                </div>
                <div className="form-group"> 
                    <div>
                        <select id="resultType" name="resultType" onBlur={handleBlur} onChange={handleChange} type="option" className="form-select"> 
                            <option  selected hidden >select Period</option>
                            <option value="0">Mid-year results</option> 
                            <option value="1">Final year resulls</option>
                        </select>                        
                    </div>                  
                </div>
            </Container>
        )
    }

    const SearchBar = ({placeholder, data}) => {
        const [filteredData, setFilteredData] = useState([])
        const [wordEntered, setWordEntered] = useState("")  
        const handleFilter = (event) => {   
            const searchWord = event.target.value
            setWordEntered(searchWord);
            const newFilter = data.filter((value) => {		
                return value['First Name'].toLowerCase().includes(searchWord.toLowerCase()) 
            })        
            if (searchWord === "") {
                setFilteredData([])
            } else {
                setFilteredData(newFilter)
            }
        }    
        const clearInput = () => {
            setFilteredData([]);
            setWordEntered("");
        }
        const handleSearchResult = (app) =>{		
            if (!selectedEmails.includes(app['Email']) ){            
                const newarr = [...selectedEmails];
                const newApp = [...selectedApps]
                newarr.push(app['Email']);
                newApp.push(app)
                setValues({...values, selectedEmails: newarr, selectedApps: newApp});
            }else {
                alert(`${app['Email']} is already selected`)  
                
            }
        }        
	  return (
	  <div >
		<div className="searchInputs" >
		  <input className="form-control" name="search" id="search"type="text" placeholder={placeholder} value={wordEntered} onChange={handleFilter}/>
		  <div className="searchIcon">
			{filteredData.length === 0 ? (
			  <SearchIcon/>
			) : (
			  <CloseIcon id="clearBtn" onClick={clearInput} />
			)}
		  </div>
		</div>
		{filteredData.length != 0 && (
		  <div className="dataResult">
			{filteredData.slice(0, 15).map((value, key) => {
			return (
				<button className="dataItem" name={value['Email']}  onClick={()=>handleSearchResult(value)}>{value['First Name']} {value['Last Name']} {value["Application Year"]}</button>
			 );
			})}
		  </div>
		)}
		{selectedApps.length != 0 && (
		  <div>
			{selectedApps.map((app,index) => {
			    return (		
                  <div> {app['Email']} <button  id={app['Email']} name={app['Email']} onClick={()=>removeApp(index)}/></div>             		  
			    );
			})}
		  </div>
		)}
	  </div>
	  );
    }
    const handleAddField =(event)=>{
        if (!selectedField.includes(upload[parseInt(event.target.value)])) {   
            selecField((prevMessage) => ([
                ...prevMessage, upload[parseInt(event.target.value)]                
            ]));
            const newarr = [...field];
            newarr.push(event.target.value);
            setValues({...values, field: newarr});           
        }
        else{
            alert(`${upload[parseInt(event.target.value)]} is already selected`)
        }
    }
    const removeField = (event) =>{
        event.preventDefault()
        selecField(selectedField.filter(item => item !== event.target.name));
        const newarr = [...field];
        newarr.pop(event.target.value);
        setValues({...values, field: newarr});
    }
    const removeApp = (index) =>{
        const newarr = [...selectedEmails];
        const newApps = [...selectedApps]
        newApps.splice(index,1)
        newarr.pop(selectedApps[index]['Email']);
        setValues({...values, selectedApps: newApps, selectedEmails: newarr})
    }
 


    const filterEmail = (e) => {
        let newFilter = []
        if (e.target.name == 'acceptedEmailsCheck'){
            newFilter = application.filter((value) => {       
                return value['accept_decline'] == 1 
            })

            setValues({...values,selectedApps: newFilter,  acceptedEmailsCheck: acceptedEmailsCheck ? false : true, paidEmailsCheck:false})
        }
        if (e.target.name == 'paidEmailsCheck'){
            newFilter = application.filter((value) => {       
                return value['accept_decline'] == 1 && value['payment'].length > 0
            })

            setValues({...values, selectedApps: newFilter, acceptedEmailsCheck:false,paidEmailsCheck: paidEmailsCheck ? false : true })
        }
        
    }
    const EmailForm = ()=>{
        return (
            <Container>
                    <div className="form-group">
                        <label className="form-label">to</label>
                    </div>
                    <div className="form-group">
                        <Checkbox key="acceptedEmailsCheck" id="acceptedEmailsCheck" name="acceptedEmailsCheck" 
                                onBlur={handleBlur}  checked={acceptedEmailsCheck} onChange={(e)=>filterEmail(e)}/> 
                        <label className="form-label">Send to all accepted applicants</label>                         
                    </div>
                    <div className="form-group">
                        <Checkbox key="paidEmailsCheck" id="paidEmailsCheck" name="paidEmailsCheck" onBlur={handleBlur} 
                        checked={paidEmailsCheck} onChange={(e)=>filterEmail(e)}/> 
                        <label className="form-label">Accepted and paid</label>
                    </div>
                    {/*<div>
                        <label className="form-label" > Specify Applicants </label>
                        <div className="alert alert-info"> You can search for emails using applicant's first name. You can also type emails, if more than one, separate emails by ; </div>
                        <SearchBar placeholder="search" data={application}/>
                    </div> */}                 
                    <div className="form-group">
                        <label className="form-label">cc</label>                                            
                         <input className="form-control" id="cc" name="cc"  onBlur={handleBlur} onChange={handleChange} type="text" value={cc}  /> 
                        { touched.cc && errors.cc ? <div  className="alert alert-danger"> { errors.cc} </div> : null}                        
                    </div>
                    <div className="form-group">
                        <label className="form-label">subject</label>                                            
                         <input className="form-control" id="subject" name="subject"  onBlur={handleBlur} onChange={handleChange} type="text" value={subject}  /> 
                        { touched.subject && errors.subject ? <div  className="alert alert-danger"> { errors.subject} </div> : null}                        
                    </div>
                    <div className="form-group">
                        <label className="form-label">body</label>                      
                        <textarea id="body" name="body" rows="15" onBlur={handleBlur} onChange={handleChange} type="text" className="form-control" value={body}/>
                        {touched.body && errors.body ? <div  className="alert alert-danger"> { errors.body } </div> : null}                 
                    </div>
                    <div> 
                        <input id="mail" type="button" value="Send" onClick={() => sendEmail() } style={{marginleft: 10, marginTop: 10}} className="btn-success form-control btn-next"/>                            
                    </div>
            </Container>
        )
    }
    const showNotes = () =>{
        return (<div> 
       { application.review.map(item => (         
             <div> { (item['Comment Date']).substring(0,10)}   { item['Comment']}</div>   
        ))} 
    </div> )
    }
     
    const showLoading = () =>
        loading && (
            <div className="alert alert-info">
                <h2>sending...</h2>
            </div>
        );

    const DisplayForm = () =>{
        switch(display){
            case 'comment':
                return Comment()
            case 'emailform':
                return EmailForm()
        }
    }
    const sendEmail1 = ()=> {
        window.scrollTo(0, 0);
        setValues({ ...values, error: false, loading: true });
        let ready = true
        let accpetedEmails = [] 
        let enteredEmails = []
        let cc = []
        if (application != undefined){
            application.map(obj => { 
                if ((obj.accept_decline == 1) && (re.test(String(obj['Email']).toLowerCase()))) {
                    accpetedEmails.push(obj['Email'])                    
                }
            }) 
        }         
        let text = document.getElementById("search").value
        if (text !== '') {
            enteredEmails = text.replace(/\s/g, "").split(';')
        }
        text = document.getElementById("cc").value
        if (text !== '') {
            cc = text.replace(/\s/g, "").split(';')
        }
        let to = [...selectedEmails,...enteredEmails]

        to.map(email =>{           
            if (!re.test(String(email).toLowerCase())) {
                setValues({ ...values, error: false, loading: false });
                ready = false
                alert(`entered ${email} not valid email`)
            }
        })

        cc.map(email =>{           
            if (!re.test(String(email).toLowerCase())) {
                setValues({ ...values, error: false, loading: false });
                ready = false
                alert(`CC ${email} not valid email`)
            }
        })

        if (acceptanceEmails == '1') {
            to = [...accpetedEmails]  
        }
        if (ready) {
 
            sendResultsRequest({to,cc,subject,resultType, applicant_id: application['applicant_id'], body}).then(data => {
                   
                    if (data.error) {
                        setValues({ ...values, error: false, loading: false });
                        alert(data.error)
                    } else {
                    setValues({ ...values, error: false, loading: false });            
                    alert(`Results request sent`)
                    }
                })
         }
        }

    const sendEmail = ()=> {
        window.scrollTo(0, 0);
        setValues({ ...values, error: false, loading: true });
        let ready = true
        let cc = []

        let text = document.getElementById("cc").value
        if (text !== '') {
            cc = text.replace(/\s/g, "").split(';')
        }
      
        cc.map(email =>{           
            if (!re.test(String(email).toLowerCase())) {
                setValues({ ...values, error: false, loading: false });
                ready = false
                alert(`CC ${email} not valid email`)
            }
        })
        console.log(resultType)
        if (ready) {                    
            console.log({cc,subject,resultType, selectedApps, body})  
            sendResultsRequest({cc,subject,resultType, selectedApps, body}).then(data => {     
                    console.log({cc,subject,resultType, selectedApps, body})              
                    if (data.error) {
                        setValues({ ...values, error: false, loading: false });
                        alert(data.error)
                    } else {
                        setValues({ ...values, error: false, loading: false });            
                        alert(`Results request sent`)
                    }
             })
            
         }
        }
    return(
        <div>
            <button onClick={()=> {   window.location.reload();}}> Back to Admin Page </button>
            {Filter()}
            <Container maxWidth="lg">
            <form>
                {AddField()}
                {EmailForm()}                 
            </form>
            </Container>
        </div>
    )
    
}
export default ResultsRequest;
