import React, { useState , useEffect, useRef} from 'react';
import { Link, Redirect, useHistory, Redirect as Red } from 'react-router-dom';
import {Form, useFormik}  from 'formik'
import * as Yup from 'yup';
import Layout from '../core/Layout';
import ApplicationManager from './ApplicationManager'
import DocumentRequest from './DocumentRequest'  
import LateApplicationRequest from './LateApplicationRequest' 
import Emails from './Emails'
import ResultsRequest from './ResultsRequest';  
import Statistics from './Statistics' 
import Review from './Review'
import {jsPDF} from 'jspdf'
import axios from 'axios'
import {DataGrid} from '@material-ui/data-grid'
import  Container from '@material-ui/core/Container'
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetail from '@material-ui/core/AccordionDetails'
import IconButton from '@material-ui/core/IconButton'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { getStatus,fechData, applicationreport, getapplicantjsondata, 
	     getApplicationFiles,sendAllAccepted, sendAllDeclined, sendComment, searchApplication } from '../util';
import SendAcceptEmails from './SendAccepetance'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import numeral from	'numeral'
import * as dump from "../dump";

const AdminDashboard = () => {
	const validationSchema = Yup.object({
        email: Yup.string().required('Enter your Eamil'),
        comment: Yup.string().required('Comment is required')
    })

	const {handleChange, handleBlur, setValues,  values, touched, errors } = useFormik({ 
        initialValues: {
			application_year: '2025',           
			university:'',
			paid: '',
			accept_decline: '',
			documents_completed: '',
			dcouments_submited: '',
			searchKey:'',
			course: '',
	        loading: false,
        },
        validationSchema
    });
	
	const [status, setStatus] = useState();
	const [report, setReport] = useState();
	const [applicantData, setapplicantData] = useState();
	const [progressInfos, setProgressInfos] = useState({ percentage: 0 });
	const [message, setMessage] = useState([]);
	const progressInfosRef = useRef(null)
	const [filteredApplicants,setFilteredApplicants ] = useState([]);
	
	const [application, setApplication ] = useState({})
	const [redirect,setRedirect] = useState('home')
    const [filterOptions, setFilterOptions] = useState({})

	const {searchKey, loading, error} = values;
	const search_field = {
              
    };

	const history = useHistory()
	
	Number.prototype.format = function(n, x, s, c) {
		var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
		num = this.toFixed(Math.max(0, ~~n));	
		return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
	};



    useEffect(  () =>{
			let mounted = true
			_searchApplication({'application_year':values.application_year})
			return ()=> {mounted = false}       
	},[]);		
	const Filter = () => {
        return (
			<div>
			    <div className="row" style={{ marginLeft: 500 }}>
				<div className="form-group col-xs-10 col-sm-4 col-md-2 col-lg-2" >
                    <div style={{ marginLeft: 20 }}>
                        <label className="form-label">
                            {"Application Year"}
                        </label>{" "}
                        <label className="form-label">
                            {" "}
                        </label>{" "}
                        <select id="application_year" name="application_year" onBlur={handleBlur} 
							onChange={(e)=>{setValues({...values,application_year:e.target.value}); _searchApplication({'application_year':e.target.value})}} type="option" className="form-select" value={values.application_year}> 
                            <option selected hidden >select year</option>
							<option value="">all</option>
							<option value="2019">2019</option>
							<option value="2020">2020</option>
							<option value="2021">2021</option>
							<option value="2022">2022</option> 
							<option value="2023">2023</option>
							<option value="2024" >2024</option>
							<option value="2025" selected>2025</option>
                        </select>      
                    </div>
                </div>    					
                <div className="form-group col-xs-10 col-sm-4 col-md-2 col-lg-2" >
					
                    <div style={{ marginLeft: 20 }}>
                        <label className="form-label" hidden >
                            {"University"}
                        </label>{" "}
                        <label className="form-label" > 
                            {" "}
                        </label>{" "}
        
						<input  id="searchKey" name="searchKey" onChange={handleSearchChange} type="text" value={searchKey}  /> 
                           
                       
                    </div>
                </div>
                <div className="form-group col-xs-10 col-sm-4 col-md-2 col-lg-2" >
                    <input
                        id="searcbutton"
                        type="button"
                        value="Search"
                        className="btn-success form-control"                        
                        onClick={() =>{_search()}}
                        style={{  marginTop: 18, marginLeft: 100, width: 100 }}
                    />
                </div>
            </div>

            <div className="row" style={{ marginLeft: 30 }}>
				
                <div className="form-group col-xs-10 col-sm-4 col-md-2 col-lg-2" >
                    <div style={{ marginLeft: 20 }}>
                        <label className="form-label" >
                            {"University"}
                        </label>{" "}
                        <label className="form-label" >
                            {" "}
                        </label>{" "}
                        <select id="university" name="university" onBlur={handleBlur} onChange={searchChange} type="option" className="form-select" value={filterOptions.university}> 
                            <option  value="all">all</option>
                            {dump.list_of_universities.map( (value) => (<option value={value} >{value}</option>))} 
                        </select>      
                    </div>
                </div>

				<div className="form-group col-xs-10 col-sm-4 col-md-2 col-lg-2" >
                    <div style={{ marginLeft: 20 }}>
                        <label className="form-label">
                            {"Accepted/Declined"}
                        </label>{" "}
                        <label className="form-label">
                            {" "}
                        </label>{" "}
                        <select id="accepted" name="accepted" onBlur={handleBlur} onChange={searchChange} type="option" className="form-select" value={filterOptions.accepted}> 
							<option selected value="all">all</option>
							<option value="ACCEPTED">accepted</option>
							<option value="DECLINED">declined</option>     
                        </select>      
                    </div>
                </div> 
				<div className="form-group col-xs-10 col-sm-4 col-md-2 col-lg-2" >
                    <div style={{ marginLeft: 20 }}>
                        <label className="form-label" >
                            {"Paid/No Paid"}
                        </label>{" "}
                        <label className="form-label" >
                            {" "}
                        </label>{" "}
                        <select id="paid" name="paid" onBlur={handleBlur} onChange={searchChange} type="option" className="form-select" value={filterOptions.paid}> 
							<option value="all">all</option>
							<option value="YES">paid</option>
							<option value="NO">not paid</option>     
                        </select>      
                    </div>
                </div>
				<div className="form-group col-xs-10 col-sm-4 col-md-2 col-lg-2" >
                    <div style={{ marginLeft: 20 }}>
                        <label className="form-label" >
                            {"Documents Submitted"}
                        </label>{" "}
                        <label className="form-label" >
                            {" "}
                        </label>{" "}
                        <select id="dcouments_submitted" name="dcouments_submitted" onBlur={handleBlur} onChange={searchChange} type="option" className="form-select" value={filterOptions.dcouments_submitted}> 
							<option value="all">all</option>
							<option value="YES">submited</option>
							<option value="NO">not submited</option>     
                        </select>      
                    </div>
                </div>  
				<div className="form-group col-xs-10 col-sm-4 col-md-2 col-lg-2" >
                    <div style={{ marginLeft: 20 }}>
                        <label className="form-label" >
                            {"Documents Completed"}
                        </label>{" "}
                        <label className="form-label" >
                            {" "}
                        </label>{" "}
                        <select id="documents_completed" name="documents_completed" onBlur={handleBlur} onChange={searchChange} type="option" className="form-select" value={filterOptions.documents_completed}> 
							<option value="all">all</option>
							<option value="YES">completed</option>
							<option value="NO">not completed</option>     
                        </select>      
                    </div>
                </div>  
             
            </div>
		</div>
        );
    };
    const handleSearchChange = (e) => {
		setValues({...values, searchKey: e.target.value})
		_search()
	}
    const searchChange = (e) => {
		let filter = {...filterOptions}
		filter[e.target.name] = e.target.value
		setFilterOptions(filter)
		_fillerSearch(filter)
	}
	const _displyResult=(results)=>{
		
		if (Array.isArray(results) && results.length > 0){
			let tmp = results.filter(item => item['period'] === "finalyear_transcript")[0]
			return `${tmp?.pass_fail ? tmp.pass_fail : "NO MARKS"} ${tmp?.confirmed == "yes" ? "CONFIRMED" : "Not Confirmed"}`
		} else return "NO RESULT"
	}
    const _searchApplication = (searchKeys) => {
		
		searchApplication(searchKeys).then(data => {	

			 let rows = []
			 if (data && data.length > 0){
				 rows = data.map(obj =>( { 
					 ...obj,  
					 id : parseInt(data.indexOf(obj) + 1),
					 full_name: `${obj['First Name']} ${obj['Last Name']}`,
					 reviews : `${obj.review.map(item => ( item['Comment'] ))}`,
					 accepted:`${ obj.accept_decline == 1 ? ('ACCEPTED') : obj.accept_decline == 0 ? ('DECLINED'): ('NO DECISION') }`,
					 result: _displyResult(obj.all_results),
					 dcouments_submitted: `${ obj.consent_form_list == undefined || obj.consent_form_list.length < 1 ? ('NO') : 'YES' }`,
					 documents_completed: `${ obj.docs_completed== undefined ? ('NO') : 'YES' }`,
					 payments: `${ obj.payment.length == 0 && obj.accept_decline == 1 ? ('not paid') : ( obj.payment.map( item => ( item['Amount Approved'])))}`,
					 university: `${obj['University']}`,
					 application_year: `${obj["Application Year"]}`,

					 paid: `${obj.payment.length > 0 && obj.accept_decline == 1 ? ('YES'):'NO'}`
				 }) )
				 setFilteredApplicants(rows)
				 setReport(rows)
			 }
		 
	 })

	}
	const _fillerSearch = (filter) => {	
        for (var key in filter) {
            if (filter[key] === undefined || filter[key] === "" || filter[key] === "all" ){
                delete filter[key]
            }
        }
        var apparr = []  
        if (report != undefined ) {
            apparr = [...report]
			setFilteredApplicants([]) 
            apparr = apparr.filter(function(item) {
                for (var key in filter) {
                    if (filter[key] === undefined || filter[key] === "" || item[key] === undefined || item[key] !== filter[key])
                        return false;                    
                }
                return true;
            })     
        }
        //let counts = counters(format(range[0].startDate,'yyyy-MM-dd'), format(range[0].endDate,'yyyy-MM-dd'),apparr)
        setFilteredApplicants(apparr)       
        //setSearch('search')
    }

	const _fillerData = (arr,filter) => {		
        var apparr = []  
        if (arr != undefined ) {
            apparr = [...arr]
	         apparr = apparr.filter(function(item) {
                for (var key in filter) {
                    if (filter[key] === undefined || filter[key] === "" || item[key] === undefined || item[key] !== filter[key])
                        return false;                    
                }
                return true;
            })     
        }

        //let counts = counters(format(range[0].startDate,'yyyy-MM-dd'), format(range[0].endDate,'yyyy-MM-dd'),apparr)
        return apparr       
        //setSearch('search')
    }

	const _search = () => {
	    var apparr = []  
        if (report != undefined ) {
            apparr = [...report]
			var re = new RegExp(searchKey,"i");
			setFilteredApplicants(apparr) 
			if (searchKey != undefined || searchKey != ""){
				apparr = apparr.filter(function(item) {
					if ((item['First Name'].match(re)) || (item['Last Name'].match(re)))
						return true 
					else return false
				})     
			}
			
        }

        //let counts = counters(format(range[0].startDate,'yyyy-MM-dd'), format(range[0].endDate,'yyyy-MM-dd'),apparr)
        setFilteredApplicants(apparr)       
        //setSearch('search')
    }
	/*const _searchApplicants = () => {
		searchApplicants(search_field).then((data) => {			
			if (data.error) {
				//setResultGrid(false);				
			}
			if (data.success) {
				let rows = [];
				rows = data.success.map((obj) => ({
					...obj,
					id: parseInt(data.success.indexOf(obj) + 1),
				}));
				//setResultGrid(true);
				//setResultData(rows);
			}
		});
	};*/
	/*const _allApplicants = () => {
		let search = { 'retrieve_all':'all'}
		searchApplicants(search).then((data) => {
			if (data.error) {
				//setResultGrid(false);
			}
			if (data.success) {
				let rows = [];
				rows = data.success.map((obj) => ({
					...obj,
					id: parseInt(data.success.indexOf(obj) + 1),
				}));
    			//setResultGrid(true);
				//setResultData(rows);
			}
		});
	};*/




	var checklistdata = []
	var checklist_object =  {}
	var i = 0
	if (report != undefined){
		report.map( obj => {
			if (obj['checklist_downloaded'] == 1 ){
                i = i + 1
				checklist_object = {
					"ID": `${i}`,
              		"Type of Application": obj['Application Type'],
					'First Name': obj['First Name'],
					'Last Name': obj['Last Name'],
					'Student Number': obj['Student Number'],
					'Cell Phone': obj['Cell Phone'],
					'Email': obj['Email'], 
					'Alternative Phone' : obj['Alternative Phone'], 
					'Institution': obj['University'], 
					'Year of Study': obj['Year of Study'],
					'Completion year': obj['Year of Study'],
					'Course of Study': obj['Course'],
					'Value Requested': parseInt(obj['Required Amount for Tuition']) + parseInt(obj['Required Amount for Books']) + parseInt(obj['Required Amount for Accomodation']),
					'Amount for books': obj['Required Amount for Books'],
	  				"Well completed application" :  `${  obj.docs_completed== undefined ? ('no') : 'yes' }`, 
					"2021 results on file":   `${  obj.result== undefined ? ('no') : 'yes' }`,
					"Passed 2021 academic year":  `${  obj['pass_or_fail'] == '1' ? 'yes' : 'no' }`, 
					"Proof of Income": `${  obj['affidavit'] ? 'yes' : 'no' }`,
					"ID  or Passport on file":`${  obj['id_document'] ? 'yes' : 'no' }`, 
					"2022 acceptance or proof of registration on file": `${  obj['registration'] ? 'yes' : 'no' }`, 
					"2022 Statement of fees on file": `${ obj['fees_statement'] ? 'yes' : 'no' }`, 
					"Recommendation Approved or Declined": `${ obj['accept_decline'] == 1 ? 'Approved' : 'Decline' }`,
					"Recommendation Approved but subject to": 'N/A',
					"Reason for Declining":  ' ', 
					"Date of Recommendation":  ''  
				}
				checklistdata.push(checklist_object)
			}
		})	
	}	
	if (report != undefined){
        var groupBy = (array, predicate) => {
			var grouped = {};
			for(var i = 0; i < array.length; i++) {
				var groupKey = predicate(array[i]);
				if (typeof(grouped[groupKey]) === "undefined")
					grouped[groupKey] = [];
				grouped[groupKey].push(array[i]);
			}
		
			return grouped;
		}
        var groupData = groupBy(report, function (obj) {
			return obj["University"];
		});	
	}	
	var pay = [] 
	if (report != undefined){ 		
		report.map( obj => {
				obj.payment.map( item => {
					pay.push(item)
				})
			})
		}
	
	if (pay) {
		pay.map(obj => {
			//delete obj['payment_id']
		
		})
	}	


 	const columns = [
		{ field: 'id', headerName: '', width: 70 },
		{ field: 'full_name', headerName: 'Name', width: 150 },
		{ field: 'University', headerName: 'University', width: 200 }, 
		{ field: 'Application Year', headerName: 'Year', width: 100 }, 
		//{ field: 'review', headerName: 'Review', width: 180 },  
		{ field: 'accepted', headerName: 'Approved', width: 150 },
		{ field: 'dcouments_submitted', headerName: 'Docs Submitted', width: 170 },
		{ field: 'documents_completed', headerName: 'Docs Completed', width: 170 },
		{ field: 'result', headerName: 'Result', width: 190 },
		{ field: 'payments', headerName: 'Payment', width: 180 },  
		//{ field: 'result', headerName: 'Result', width: 180 },	
	];
	const saveComment = (comment, applicant)=> {
        //setValues({ ...values, error: false, loading: true });
        sendComment( comment, applicant ).then(data => {
            if (data.error) {
                alert(data.error)
            } else {
				setValues({ ...values, error: false, loading: false });
				alert('Comment Saved')
            }
        });
    }
	
	const sendDeclined  = () =>{
		setValues({...values, loading: true });
		sendAllDeclined().then( data =>{
			if (data.error) {
				alert(data.error)				
			}
			else {
				setValues({...values, loading: false });
				alert('Declined letters are sent out to all declined applicants ')
			}
		})
	}
	const sendAccepted   = () =>{
		setValues({...values, loading: true });
		sendAllAccepted().then( data =>{
			if (data.error) {
				alert(data.error)				
			}
			else {
				setValues({...values, loading: false });
				alert('Acceptance letters are sent out to all accepted applicants ')
			}
		})
	}
	const showLoading = () =>
	loading && (
		<div className="alert alert-info">
			<h2>Sending...</h2>
		</div>
	);
 	
	const downloadapplicationfrom = () => {   
		axios.get('/api/downloadapplicationfrom', {
			responseType: 'blob',
			headers: {
				'Access-Control-Allow-Origin' : '*',
         		'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS', 
				'Access-Control-Allow-Headers': '*',
				'Access-Control-Max-Age': '1728000'

			},
      		onDownloadProgress: (progressEvent) => {
        		setProgressInfos({ ...progressInfos, percentage: Math.round((progressEvent.loaded * 100) / progressEvent.total)})
        	
      		}
    	})
    	.then((response) => {
			const data = new Blob([response.data])
			FileSaver.saveAs(data, "applicationforms.zip")
			/*const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
      		const link = document.createElement('a');
      		link.href = downloadUrl;
      		link.setAttribute('download', 'all_applicants_data.zip'); 
      		document.body.appendChild(link);
      		link.click();
      		link.remove();*/
   	    })
    }
  

	const downloadChecklist = () => {   
		axios.get('/api/getchecklistfiles', {
			responseType: 'blob',
      		onDownloadProgress: (progressEvent) => {
        		setProgressInfos({ ...progressInfos, percentage: Math.round((progressEvent.loaded * 100) / progressEvent.total)})
        	
      		}
    	})
    	.then((response) => {
			const data = new Blob([response.data])
			FileSaver.saveAs(data, "checklist.zip")
			/*const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
      		const link = document.createElement('a');
      		link.href = downloadUrl;
      		link.setAttribute('download', 'all_applicants_data.zip'); 
      		document.body.appendChild(link);
      		link.click();
      		link.remove();*/
   	    })
    }
  	const downloadFolder = () => {
		setProgressInfos(50)
		axios.get('/api/getapplicationfiles', {
			responseType: 'blob',
      		onDownloadProgress: (progressEvent) => {				
        		setProgressInfos({ ...progressInfos, percentage: Math.round((progressEvent.loaded * 100) / progressEvent.total)})        	
      		}
    	}).then((response) => {
			const data = new Blob([response.data])
			FileSaver.saveAs(data, "all_applicants_data.zip")
			/*const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
      		const link = document.createElement('a');
      		link.href = downloadUrl;
      		link.setAttribute('download', 'all_applicants_data.zip'); 
      		document.body.appendChild(link);
      		link.click();
      		link.remove();*/
   	    })
    }



    
	const ExportChecklistToExcel = ({ apiData, fileName }) => {
		const fileType =
		  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
		const fileExtension = ".xlsx";
	    const exportToCSV = (apiData, fileName) => {
			if (filteredApplicants != undefined ) 
				var csv_data = filteredApplicants.map(abj => {return {...abj}})
			if (csv_data != undefined){
				csv_data.map(obj => {
					delete obj.application_id
					delete obj.applicant_ids
				})
			}
			const ws = XLSX.utils.json_to_sheet(csv_data);
			const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
			const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
			const data = new Blob([excelBuffer], { type: fileType });
			FileSaver.saveAs(data, `checklist2022` + fileExtension);
		};
	  
		return (
		  <button onClick={(e) => exportToCSV(apiData, fileName)}>Download Checklist Sheet</button>
		);
	  }


	const ExportToExcel = () => {
		const fileName = "bursary_2023"
		const fileType =
		  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
		const fileExtension = ".xlsx";
	    const exportToCSV = ( fileName) => {
			if (filteredApplicants != undefined ) 
				var csv_data = filteredApplicants.map(abj => {return {...abj}})
			if (csv_data != undefined){
				csv_data.map(obj => {
					delete obj.application_id
					delete obj.applicant_ids
				})
			}
			const ws = XLSX.utils.json_to_sheet(csv_data);
			const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
			const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
			const data = new Blob([excelBuffer], { type: fileType });
			FileSaver.saveAs(data, fileName + fileExtension);
		};
	  
		return (
		  <button onClick={(e) => exportToCSV()}>Download Excel Sheet</button>
		);
	  }

	  const ExportPaymentToExcel = ({ apiData, fileName }) => {
	
		const fileType =
		  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
		const fileExtension = ".xlsx";	    
		const exportToCSV = (apiData, fileName) => {
		const ws = XLSX.utils.json_to_sheet(apiData);
		const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, fileName + fileExtension);
		};
	  
		return (
		  <button onClick={(e) => exportToCSV(apiData, fileName)}>Download Payment Excel Sheet</button>
		);
	  };
	
	
    const ProgressBar = ({percentage }) => {
  	    return (
		    <div className="progress">
        		<div className="progress-bar" role="progressbar" style={{ width: `${percentage}%`}} > {percentage}%</div>
            </div>
		)
    }

	const Stat = () => {
		let all
		let nonMuslim = 0, review = 0,pending=0, approved=0, declined=0 
		let paid = 0, recieved = 0, completed =0

		if (filteredApplicants != undefined){
			filteredApplicants.map( row => {
				if (row['Application Year'] == values.application_year){
					all = filteredApplicants.length
					if (row['Religion'] == 'Other'){
						nonMuslim = nonMuslim + 1
					}
					if (row['review'] !== ''){
						review = review + 1
					}
					if (row['accept_decline'] == 0){
						declined = declined + 1
					}
					if (row['accept_decline'] == 1){
						approved = approved + 1
					}
					if (row['accept_decline'] == 2 ){
						pending = pending + 1
					}
					if (( row['accept_decline'] == 1 ) && (row['payment'].length > 0 )){
						paid = paid + 1
					}	
					if ( row.consent_form_list.length > 0 ){
						recieved = recieved + 1
					}	
					if ( row['docs_completed'] == 1){
						completed = completed + 1
					}	
				}
			} )
		}
		return (
			<Container>
			   <h>All: {all}, </h> <h>Pending: {pending}, </h> <h>Declined: {declined}, </h> <h>Approved: {approved}, </h> <h> Requested Docs submitted: {recieved}, </h><h> Documents completed: {completed}, </h>
               <h> Paid: {paid}, </h>			   
			</Container>
		)
	}
	
	const exportPaymentSheet = ( paydata, start, end) => {
		let sum = 0
		let payarray = []
		let  datestart = moment(start).format('YYYY-MM-DD');
		let dateend = moment(end).format('YYYY-MM-DD');
		if (paydata.length > 0){
			paydata.map(obj =>{
				if ((obj['Payment Date'] >= datestart) && (obj['Payment Date'] <= dateend) ){
					payarray.push(obj)
				}
			})
		}
		payarray.map(obj => {			   
			sum = sum + parseFloat(obj['Amount Approved'])		
			obj['ID'] = payarray.indexOf(obj) + 1     
			delete obj['payment_id']
		   //delete obj['Payment Date']
     	})
		payarray.push({'In respect of Education/Debt': 'Total', 'Amount Approved':   numeral(sum).format('0,00') })
		const fileType =
		  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
		const fileExtension = ".xlsx"
		const ws = XLSX.utils.json_to_sheet(payarray)
		const wb = { Sheets: { data: ws }, SheetNames: ["data"] }	
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
		
		const data = new Blob([excelBuffer], { type: fileType })
		FileSaver.saveAs(data, `bursary_payment_sheet_${datestart}_${dateend} + ${fileExtension}`)
	}
	const SendDecline = () => {	
		const [open, setOpen] = React.useState(false);
		const handleClickOpen = () => {
			setOpen(true);
		  };
		
		  const handleClose = () => {
			setOpen(false);
		  }
	  
		return (
		  <div>
			<button  onClick={handleClickOpen}>
			SEND DECLINE LETTERS
			</button>
			<Dialog
			  open={open}
			  onClose={handleClose}
			  aria-labelledby="alert-dialog-title"
			  aria-describedby="alert-dialog-description"
			>
			  <DialogTitle id="alert-dialog-title">
				{"SANZAF burasry"}
			  </DialogTitle>
			  <DialogContent>
				<DialogContentText id="alert-dialog-description" className="form-label">
				<p className="form-label">Do you want to send decline letters to the all declined applicants?</p>
				</DialogContentText>
			  </DialogContent>
			  <DialogActions>
				<Button onClick={handleClose}>No</Button>
				<Button onClick={() => { handleClose(); sendDeclined() }} autoFocus>
				  Yes
				</Button>
			  </DialogActions>
			</Dialog>
		  </div>
		);
	  }
		  
	  const SendAcceptance = () => {
		
		const [open, setOpen] = React.useState(false);
		const handleClickOpen = () => {
			setOpen(true);
		  };
		
		  const handleClose = () => {
			setOpen(false);
		  }
	  
		return (
		  <div>
			<button  onClick={handleClickOpen}>
			SEND ACCEPTANCE LETTERS
			</button>
			<Dialog
			  open={open}
			  onClose={handleClose}
			  aria-labelledby="alert-dialog-title"
			  aria-describedby="alert-dialog-description"
			>
			  <DialogTitle id="alert-dialog-title">
				{"SANZAF bursary"}
			  </DialogTitle>
			  <DialogContent>
				<DialogContentText id="alert-dialog-description">
				 <p className="form-label"> Do you want to send acceptance letter to the all accepted applicants? </p>
				</DialogContentText>
			  </DialogContent>
			  <DialogActions>
				<Button onClick={handleClose}>No</Button>
				<Button onClick={()=> {handleClose(); sendAccepted()}} autoFocus>
				  Yes
				</Button>
			  </DialogActions>
			</Dialog>
		  </div>
		);
	  }


	
	  const PaymentDownload = () => {
		const [open, setOpen] = React.useState(false);
		const [startDate,setStartDate] = useState()
		const [endDate,setEndDate] = useState()
	    var start, end
		const handleClickOpen = () => {
			setOpen(true);
		  };
		  const handleClose = () => {
			setOpen(false);
		  }
		return (
		  <div>
			<button  onClick={handleClickOpen}>
				Download Payment Sheet
			</button>
			<Dialog
			  fullWidth
			  maxWidth="xs"
			  open={open}
			  onClose={handleClose}
			  aria-labelledby="alert-dialog-title"
			  aria-describedby="alert-dialog-description"
			>
			  <DialogTitle id="alert-dialog-title">
				{"SANZAF bursary"}
			  </DialogTitle>
			  <DialogContent style={{height:'300px'}}> 
           		<DialogContentText id="alert-dialog-description">
					 <p> <div>  <label className="form-label" >From:</label> 	<DatePicker selected={startDate}  onChange={  date => {
					 
						 setStartDate(date)
						 }} /> </div> </p>
					</DialogContentText>
					<DialogContentText id="alert-dialog-description">
					 <p> <div> <label className="form-label" >{"    To:"}</label> 	<DatePicker  selected={endDate}  onChange={ date => {
    				
   					setEndDate(date)}}  /> </div> </p>
					</DialogContentText>
					
					<Button onClick={()=> {handleClose(); exportPaymentSheet(pay,startDate,endDate)}} autoFocus>
					Ok
					</Button>
					<Button onClick={handleClose}>Cancel</Button>
			  </DialogContent>
			</Dialog>
		  </div>
		);

	  }

	  const saveApplication = (app) =>{
		  localStorage.setItem('applicationData',  JSON.stringify(app))
		  window.open('/applicationmanager', "_blank")
		   //history.push('/applicationmanager')
	  }
	  const redirectPage = () => {
		switch(redirect){
			case "home":
				return <Home/>
			case "accept":
				return <SendAcceptEmails />
			case "app":
				// return <ApplicationManager application={application} />
				history.push("/applicationmanager", { application: application})
			case "request":
				return <DocumentRequest application={_fillerData(report, {"application_year": "2025"})} /> 
			case "stat":
				return <Statistics application={filteredApplicants} />
			case "late":
				return <LateApplicationRequest/>
			case "email":
				return <Emails application={filteredApplicants}/>
			case "results":
				return <ResultsRequest application={_fillerData(report, {"application_year": "2025"})}/>
		}		
	}
	const ToolBar = ()=>{
		return (
			<div>
					<ExportToExcel/>
					<ExportChecklistToExcel apiData={checklistdata} fileName={"bursary_2025"} />
					
					{/* <ExportPaymentToExcel apiData={pay} fileName={"bursary_payment_2022"} /> */}
					<PaymentDownload/>
					<button onClick={() => downloadFolder()}>Download all Applicants Files </button>
					   <button onClick={() => downloadChecklist()}>Download Checklist Files </button>
					  
					   <button onClick={() => downloadapplicationfrom()}>Download Application Forms  </button>
					<button onClick={() =>setRedirect('request')}>Documents Request  </button>
					<button onClick={() =>setRedirect('results')}>Results Request</button>
					<button onClick={() =>setRedirect('email')}>General Emails</button>
					<button onClick={() =>setRedirect('late')}>Late Application Request  </button>
				
					<button onClick={() =>setRedirect('stat')}>Statistics   </button>
					<SendAcceptance/>
				    <SendDecline/>
					{progressInfos && <div> <ProgressBar percentage={progressInfos.percentage}/> </div>  }
					<Container maxWidth="xs">
                        {showLoading()}   
                    </Container>
				
					
					<Stat></Stat>
					
			</div>	
		)
	}

	const Home = ()=> {
		return (
				<div style={{marginLeft: 0 }}>
					{ ToolBar()}
                   
				</div>  
		)
	}
    return (
		<Layout>
	
			<div>
			<Container>
				 { redirect == "app" && <button onClick={()=> { window.location.reload();}}>Back to Admin Page</button>}
			</Container>
			{ redirectPage()}
			{
				redirect === "home" && <div style={{marginLeft: 0}}>
					{Filter()}
					{ report != undefined && 
                    <div style={{marginTop: 20, height: 700, width:'100%'  }}> 
          	          	<DataGrid rows={filteredApplicants} 
									columns={columns} 								
									pageSize={8} autoPageSize={true}
									onCellClick={(params) => { saveApplication(report[params.row.id - 1]); }} >
						</DataGrid>   
                    </div>} 

				</div>
			}
	
			</div>
        </Layout>
    );
}
export default AdminDashboard;
