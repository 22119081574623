import React, { useState, useRef, useEffect } from "react";
import { browserHistory } from 'react-router'
import { Link, withRouter, useHistory } from "react-router-dom";
import { useForm, useStep } from "react-hooks-helper";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Checkbox from '@material-ui/core/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import jwt_decode from 'jwt-decode';

import { Form, useFormik } from "formik";


import { Button } from "react-bootstrap";
import { Input } from "reactstrap";
import * as Yup from "yup";


import Container from "@mui/material/Container";
import * as dump  from '../dump'
import Layout from '../core/Layout';
import {addResults,uploadResults} from '../util'
const UploadResults = ({match:{params:{token}}}) => {
    var data = jwt_decode(token) 

    console.log(data)
    const history = useHistory();
    const FILE_SIZE = 5097152;
 
    const  validationSchema = Yup.object({   
        subjectMark: Yup.number('Enter digits only'),
        reason: Yup.string().required('Enter reason/s for no results'),                  
        midyear_transcript: Yup.mixed().test("fileSize",`Transcript file must be at least 2 MB. \n Try to compress the file and upload it again`, 
           value => value && value.size <= FILE_SIZE )
       .test(
       "fileFormat","Transcript/Progress report file format unsupported. Upload PDF or IMAGE file",
           value => value && SUPPORTED_FORMATS.includes(value.type)),
        finalyear_transcript: Yup.mixed().test("fileSize",`Transcript file must be at least 2 MB. \n Try to compress the file and upload it again`, 
        value => value && value.size <= FILE_SIZE )
       .test(
       "fileFormat",
       "Transcript/Progress report file format unsupported. Upload PDF or IMAGE file",
           value => value && SUPPORTED_FORMATS.includes(value.type)),})

    const SUPPORTED_FORMATS = [
      "application/pdf",
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "image/png"
    ];



    const {
        handleSubmit,
        handleChange,
        handleBlur,
        setValues,
        setFieldValue,
        values,
        touched,
        errors,
    } = useFormik({
        initialValues: {
            no_result:'',
            reason:'',
            subjectName:'',
            subjectMark:'',
            application_id: data.sub.application_id,
            midyear_transcript:'',
            finalyear_transcript:'',
            results:[],
            loading: false,
            success: false,
            error: "",
         },
        validationSchema,
        validateOnChange: true,
    });

    const {
        no_result,
        reason,
        midyear_transcript,
        finalyear_transcript,
        first_name ,
        last_name,
        email,   
        results,
        subjectName,
        subjectMark,
        application_id,  
        loading,
        error,
        success,
    } = values;

    const [progressInfos, setProgressInfos] = useState({ val: [] });
    const progressInfosRef = useRef(null)
    const [message, setMessage] = useState([]); 
    const [open, setOpen] = useState(false);
    const [startDate,setStartDate] = useState()
    const [endDate,setEndDate] = useState()
    var resultFileToUpload = {}
    var fileError = {}
    var subjectError = { subjectMark: errors.subjectMark}

    resultFileToUpload = data.sub.resultType == '0' ? {"name": "midyear_transcript", "fileObject": midyear_transcript} : {"name": "finalyear_transcript", "fileObject": finalyear_transcript}
    fileError = data.sub.resultType == '0' ? { midyear_transcript: errors.midyear_transcript } : {finalyear_transcript: errors.finalyear_transcript}

    const handleAddSubject = (event) => {
        let newArr = [...results];  
        newArr.push({'subjectName': subjectName,  'subjectMark': subjectMark })  
        setValues({...values, results: newArr});  

    }
    const _addResults = (results) => {          
        addResults(results).then((data) => {
            if (data.error) {
                alert(data.error);
                //setValues({ ...values, error: data.error, success: false });
            } else {
                setValues({ ...values, loading: false });
                setValues({ ...values, success: true });               
            }
        });
    };
    const  noError1 = (obj) => {

        if (obj.length <= 0) { 
            alert('No file was selected')
            return false
        }
        else{  
            for(let i=0; i<obj.length; i++){
              if (obj[i]['error'] != undefined){
                  alert(`${obj[i]['error']}`)
                  return false;
              }
              }
        }
          return true;
    }
    const  noSubjectError = (err) => {
        for (var key in err) {
            if (err[key])
                return err[key];
        }
        return false;
    }
    const  noError = (err) => {
        if (err['error']){
            return err['error']
        }
        return false;
    }
    
    const  notEmpty = (obj) => {        
        if (obj['fileObject'] == ""){
            alert(`Select File for ${obj['name']}`)
            return false;
        }
        
        return true;
    }
    const submitResults = () => {
     
       
        let resultsData = {application_id,results,resultFileToUpload}
        
        let _progressInfos = [{ percentage: 0, key: resultFileToUpload['name'], fileName: resultFileToUpload['fileObject'].name }]
        progressInfosRef.current = {
          val: _progressInfos,
        }
        sendResults(resultsData);
       
    }

    const sendResults = (resultsData) => { 
        let _progressInfos = [...progressInfosRef.current.val];
        return uploadResults(resultsData, (event) => {
          _progressInfos[0].percentage = Math.round(
            (100 * event.loaded) / event.total
          );
          setProgressInfos({ val: _progressInfos });
        })
          .then((res) => {
            setMessage((prevMessage) => ([
              ...prevMessage,
              resultsData.resultFileToUpload['fileObject'].name + "  uploaded  successfully. " 
            ]));
            setValues({ ...values, loading: false }); 
          })
          .catch(() => {
            _progressInfos[0].percentage = 0;
            setProgressInfos({ val: _progressInfos });
    
            setMessage((prevMessage) => ([
              ...prevMessage,
              resultsData.resultFileToUpload['fileObject'].name  + " failed to upload"
            ]));
          });
    }
  
    const handleReasonChange = (e)=>{
        let newArr = [];  
        newArr.push({'subjectName': 'no results',  'subjectMark': e.target.value })  
        setValues({...values, results: newArr});  
    }
     
    const showLoading = () => (
        <div
        className="alert alert-info"
            style={{ display: loading ? "" : "none", width: 600 }}
        >
                
                <div>
                    saving receipt ...
                </div>
                
        </div>
    );
    const showError = () => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none", width: 600 }}
        >
              
        </div>
    );
    const ProgressBar = ({progress }) => {       
  
        return  <div className="progress">
              <div className="progress-bar" role="progressbar" style={{ width: `${progress.percentage}%`}} > {progress.percentage}%</div>
         </div>
      }
  
    const AddSubject = () => {
      
          var start, end
        
      
        const handleClickOpen = () => {
          setOpen(true);
          };
        
          const handleClose = () => {
          setOpen(false);
          }

          
        
        return (
          <div>
          <button  onClick={handleClickOpen}>
          Add subject
          </button>
          <Dialog
            fullWidth
            maxWidth="xs"
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
            {"SANZAF bursary"}
            </DialogTitle>
            <DialogContent
                        style={{height:'300px'}}> 
              
              <DialogContentText id="alert-dialog-description">
              <p>  <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4">
                        <label className="form-label">Subject name</label>
                        <input 
                            className="form-control"
                            name="subjectName"
                            id="subjectName"
                            onChange={handleChange}
                            type="text"
                        />     
                    </div>
                    <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4">
                        <label className="form-label">Mark awarded</label>
                        <input 
                            className="form-control"
                            name="subjectMark"
                            id="subjectMark"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="text"
                        />  
                  {touched.subjectMark && errors.subjectMark ? ( <div style={{width: 400}} className="alert alert-danger">  {" "} {errors.subjectMark}{" "}</div>) : null}    
                    </div> </p>
              </DialogContentText>
              
              
              <Button onClick={()=> { if (subjectMark && subjectName){
                                            if (!noSubjectError(subjectError)){ handleAddSubject(); handleClose()} else {alert('Enter number for Mark awarded')};

                                       } else {alert('Enter Subject and Subject Mark')}  }} autoFocus>
              Ok
              </Button>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogContent>
          </Dialog>
          </div>
        );

	  }    
    return (
        <Layout>
        <Container maxWidth='sm' style={{ marginTop: 70, marginLeft: 400 }} >
        <div className="row" >
        
       
        <div className="clearfix"></div> 
        <div class="form-single-column" role="group" aria-labelledby="label_38" data-component="checkbox">
                <span class="form-checkbox-item" style={{}}>
                     <span class="dragger-item">
                          </span>
                          <Checkbox key="no_result" id="no_result" name="no_result" onBlur={handleBlur} onChange={handleChange} value='result' /> 
                               <label id="label_input_38_0" for="input_38_0"> No results available</label>
                          </span>
                      </div>
                    </div>
            <div>
  
          { 
              results.map( (value,index) => (
                  <div>
                <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4">
                    <label className="form-label"> Subject {value['subjectName']} </label>
                        
                </div>
                <div className="form-group col-xs-10 col-sm-4 col-md-4 col-lg-4">
                <label className="form-label"> Mark {value['subjectMark']} </label>
                     
                </div>
                <div className="clearfix"></div> 
                </div>
               
             ))
          }
     
           {no_result == 'result' && <div className="form-group">
                        <label className="form-label" > Specify the reason/s </label>  <label className="form-label required">*</label>
                        <div>   
                            <textarea id="reason" name="reason"  onBlur={handleBlur} onChange={handleReasonChange}  className="form-control"/>     
                        </div>
                    </div> }
         <div className="form-group">
            <div>
                { no_result != 'result' && <div>{AddSubject()}</div>}
             </div>                  
        </div>
       <div className="form-group"> 
       { data.sub.resultType === '0' && <div className="form-group">
                <label className="form-label" >Mid Year Transcript/Progress report</label> 
                <div   >                           
                    <Input  id="midyear_transcript" name="midyear_transcript" placeholder={midyear_transcript.fileName} className="form-control" onBlur={handleBlur}  onChange={(e) =>{
                                                    setValues({...values, midyear_transcript: e.target.files[0]});
                                                    progressInfos.val.map((progress, index) => { if (progress['key'] === 'midyear_transcript') { progress.percentage = 0} });
                                                    }} type="file" /> 
                                                    {midyear_transcript.fileName}
                    {progressInfos && progressInfos.val.length > 0 && <div> { progressInfos.val.map((progress) => progress['key'] === 'midyear_transcript' ?  <div> <ProgressBar progress={progress}/> </div> : null)} </div> }
                    { touched.midyear_transcript && errors.midyear_transcript ? <div  className="alert alert-danger">  { errors.midyear_transcript} </div> : null}
                    
                </div>
            </div> }
            { data.sub.resultType === '1' && <div className="form-group"> 
                <label  className="form-label" >Final Year Transcript/Progress report </label>
                <div   >                           
                    <Input id="finalyear_transcript" name="finalyear_transcript" placeholder={finalyear_transcript.fileName}  className="form-control" onBlur={handleBlur} onChange={(e) =>{
                                                    setValues({...values, finalyear_transcript: e.target.files[0]});
                                                    progressInfos.val.map((progress) => { if (progress['key'] === 'finalyear_transcript') { progress.percentage = 0} });
                                                    }} type="file" /> 
                    {progressInfos && progressInfos.val.length > 0 && <div> { progressInfos.val.map((progress) => progress['key'] === 'finalyear_transcript' ?  <div> <ProgressBar progress={progress}/> </div> : null)} </div> }
                    { touched.finalyear_transcript && errors.finalyear_transcript ? <div  className="alert alert-danger">  { errors.finalyear_transcript} </div> : null}
                </div>
            </div> }
      </div> 
      <div id="cid_38" class="form-input-wide jf-required">
     
                <input
                    id="paymentbutton"
                    type="button"
                    value={'Submit'}
                    style={{ marginTop: 20, width: 580 }}
                    className="btn-success form-control"
                    onClick={() =>{ setMessage([]); if (notEmpty(resultFileToUpload) && !noError(fileError)) { submitResults();  }}}                                                                         
                    
                />
            </div>
            <div>  {message.length > 0 && ( <div className="alert alert-secondary" role="alert"> <ul>   {message.map((item, i) => { return <li key={i}>{item}</li>;
                                             })}
                                           </ul>
                                              </div>  )}  
            </div>                                        

        </div>
        
    </Container>
    </Layout>
   
            
    )



};

export default UploadResults;