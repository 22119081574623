import React, { useState , useEffect} from 'react';
import { Link } from 'react-router-dom';
import Layout from '../core/Layout';
import {Container} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetail from '@material-ui/core/AccordionDetails'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {jsPDF} from 'jspdf'
import axios from 'axios'
import { getStatus, applicationreport, getApplicant, getApplication, downloadFile, fechData } from '../util';





const Profile = () => {
    var fileURL
    const [status, setStatus] = useState();
    const [report, setReport] = useState();
    const [applicant, setApplicant] = useState()
    const [application, setApplication] = useState()
    const [data, setData] = useState()
    const [redirect,setRedirect] = useState('home')
    
    const application_id =  JSON.parse(localStorage.getItem('application_id'))
    useEffect(  () =>{
        let mounted = true
            fechData().then(data => {				
            if (mounted){
                setData(data)
            }
        })
        return ()=> {mounted = false}       
    },[]); 
    useEffect(  () =>{ getStatus().then(data =>             
        setStatus(data))
    },[]);
    useEffect(() =>{ applicationreport().then(data1 =>             
        setReport(data1))
    },[]); 
   
    if (data != undefined ){
        var { 
            "First Name": first_name1,"Last Name": last_name1, "Email": email1,"Address": address1, "Suburb": suburb1, 
              "City": city1, "Province": province1, "Area Code": area_code1, "Country of Birth": country1, 
              "First Language": first_language1,"Second Language": second_language1, "ID Number": id_number1,
              "Date of Birth": date_of_birth1,"Cell Phone": cell_phone1, "Alternative Phone": alternative_phone1,
               "Alternative Email": alternative_email1,
               
               "Gender": gender1,"Race": race1, "Religion": religion1, "Employment Status": employment_status1, 
              "Marital Status": marital_status1, "Number of Siblings": number_of_siblings1,
              "Spouse Name": spouse_name1, "Spouse Contact Number": spouse_contact1, 
              "Spouse Employment Status": spouse_employment1,  "Reference Name": reference_name1, 
              "Reference Email":reference_email1, "Reference Cell Phone": reference_cell1, 
              "Relationship with Reference": reference_relationship1, 
              
               "Application Type": application_type1, "Application Year": application_year1, 
                "Student Number": student_number1,"University": university1,
                "Course": course1, "Study Level": unilevel1, "Duration of Study": duration_of_study1, "Year of Study": year_of_study1, 
                "Completion Year": completion_year1,   "Required Amount for Tuition": amount_for_tuition1,
                 "Required Amount for Books": amount_for_books1,"Required Amount for Accomodation": amount_for_accommodation1,
                
                "Father Monthly Salary": father_monthly_salary1, "Mother Monthly Salary": mother_monthly_salary1,"Spouse Monthly Salary": spouse_monthly_salary1,
                "Own Monthly Salary": own_monthly_salary1, "Investments": investments1, "Other Source of Income": other_income1, "Total Income": total_income1,

                "Rent Bond Expense": rent_bond_expense1,"Rates": rates1, "Water Electricity": water_electricity1, "Groceries": grocery_milk_bread1, 
                "Transport": transport_petrol1, "Telephone": telephone1, "Medical Aid": medical_aid1, "Insurance":insurance1, 
                "Other Expense": other_expense1, "Total Expense": total_expense1,
                
                "the_course_you_wish": the_course_you_wish1, "Academic Achievements": academic_achievements1, 
                "Community Involvement": community_involvement1, "competing": competing1, "How Did You Learn": how_did_you_learn1, 
                "Parents Assisted by SANZAF": ssisted_by_sanzaf1, "Was the application form easy to access": was_the_application_form_easy1, 
                "Was the opening date suitable": opening_date1, "Was the closing date suitable": closing_date1,
                "Were the instructions clear": instructions_clear1 } = data

            var  basicinfo1 = { 
                "First Name": first_name1,"Last Name": last_name1, "Email": email1,"Address": address1, "Suburb": suburb1, 
                "City": city1, "Province": province1, "Area Code": area_code1, "Country of Birth": country1, 
                "First Language": first_language1,"Second Language": second_language1, "ID Number": id_number1,
                "Date of Birth": date_of_birth1,"Cell Phone": cell_phone1, "Alternative Phone": alternative_phone1,
                "Alternative Email": alternative_email1, 
            
            }

            var  socioinfo1 = {     "Gender": gender1,"Race": race1, "Religion": religion1, "Employment Status": employment_status1, 
            "Marital Status": marital_status1, "Number of Siblings": number_of_siblings1,
            "Spouse Name": spouse_name1, "Spouse Contact Number": spouse_contact1, 
            "Spouse Employment Status": spouse_employment1,  "Reference Name": reference_name1, 
            "Reference Email":reference_email1, "Reference Cell Phone": reference_cell1, 
            "Relationship with Reference": reference_relationship1, 
            } 
    
        var inistitution1 = { 
            "Application Type": application_type1, "Application Year": application_year1, 
            "Student Number": student_number1,"University": university1,
            "Course": course1, "Study Level": unilevel1, "Duration of Study": duration_of_study1, "Year of Study": year_of_study1, 
            "Completion Year": completion_year1,   "Required Amount for Tuition": amount_for_tuition1,
             "Required Amount for Books": amount_for_books1,"Required Amount for Accomodation": amount_for_accommodation1
        }

        var income1 =  {
            "Father Monthly Salary": father_monthly_salary1, "Mother Monthly Salary": mother_monthly_salary1,"Spouse Monthly Salary": spouse_monthly_salary1,
            "Own Monthly Salary": own_monthly_salary1, "Investments": investments1, "Other Source of Income": other_income1, "Total Income": total_income1 
        }

        var expense1 =  { 
            "Rent Bond Expense": rent_bond_expense1,"Rates": rates1, "Water Electricity": water_electricity1, "Groceries": grocery_milk_bread1, 
            "Transport": transport_petrol1, "Telephone": telephone1, "Medical Aid": medical_aid1, "Insurance":insurance1, 
            "Other Expense": other_expense1, "Total Expense": total_expense1,
        }

        var survey1 =   { 
            "the_course_you_wish": the_course_you_wish1, "Academic Achievements": academic_achievements1, 
            "Community Involvement": community_involvement1, "competing": competing1, "How Did You Learn": how_did_you_learn1, 
            "Parents Assisted by SANZAF": ssisted_by_sanzaf1, "Was the application form easy to access": was_the_application_form_easy1, 
            "Was the opening date suitable": opening_date1, "Was the closing date suitable": closing_date1,
            "Were the instructions clear": instructions_clear1 
            
        }
     
    }
    var upload3  = { 
        "ID Document": 'id_document' ,"Acceptance Letter": 'acceptance_letter' , "Proof of Income": 'affidavit' , 
        "Testimonial Letter": 'recommendation' ,"Motivational Letter": 'motivation_letter'   
    }
    const Reviewfrom = () => {
       
        return <div> 
                
                    <Report lable="BASIC INFORMATION" stage="basicinfo" data={basicinfo1}/>
                    <Report lable="SOCIAL INFORMATION" stage="socioinfo" data= {socioinfo1}/>
                    <Report lable="UNIVERSITY" stage="application" data= {inistitution1}/>
                    <Report lable="INCOME" stage="application" data= {income1}/>
                    <Report lable="EXPENSES" stage="application" data= {expense1}/>
                    <Report lable="SURVEY" stage="application" data= {survey1}/>
                    {  Object.keys(upload3).map( key =>
					  		<FileReport lable={key}  stage="application" data= {upload3[key]}/>
					  )}
   
            </div>
    }
    const Displayfile = ({id})=>{
		return (<iframe src="" id={id}  width="500" height="600"/>)
	}
    const FileReport = ({lable, stage, data}) => {
		return ( 
				<Accordion onClick={ ()=>{ downloadFile(JSON.parse(localStorage.getItem('log')).application_id,data).then(res => {
					const blobfile = new Blob([res.data])
                    console.log(res.data)
					fileURL = window.URL.createObjectURL(res.data);				
					document.getElementById(data).src = fileURL						
					});		
					}} >
					<AccordionSummary on expandIcon={<ExpandMoreIcon />}> {lable}
					</AccordionSummary>
					<AccordionDetail> 																
						<Displayfile id={data}/>
                        
					</AccordionDetail>
				</Accordion >
		   )     
	}
  
    const Report = ({lable,stage, data}) => (        
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}> {lable}</AccordionSummary>
            <AccordionDetail> 
                <div>
                    <table >
                       { data != undefined &&
                        <tr>
                        { Object.keys(data).map(value => <div> <th> {value} </th>  <td> {data[value]}</td>  </div>)}                        
                        </tr>  }      
                    </table>
                
                </div>
            </AccordionDetail>
        </Accordion>       
    )
    const downloadReport = () => {   
		downloadFile(JSON.parse(localStorage.getItem('log')).user,'report')
    	.then((response) => {
            console.log(response.data)
			const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
      		const link = document.createElement('a')
      		link.href = downloadUrl
      		link.setAttribute('download', 'bursary_confirmation.pdf')
      		document.body.appendChild(link)
      		link.click()
      		link.remove()
   	    })
    }
    return (
        <Layout >
            <Container maxWidth="lg">
                <Reviewfrom /> 
            </Container>       
            
        </Layout>
    );
};

export default Profile;
