import React, { useState , useEffect} from 'react';
import { Link, withRouter , useHistory} from "react-router-dom";

import {useForm, useStep } from 'react-hooks-helper'
import {Form, useFormik}  from 'formik'
import * as Yup from 'yup';
import  Container from '@material-ui/core/Container'
import FormLabel from '@material-ui/core/FormLabel'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Typography from '@material-ui/core/Typography'; 
import { makeStyles } from '@material-ui/core/styles';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox';
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetail from '@material-ui/core/AccordionDetails'
import IconButton from '@material-ui/core/IconButton'
//import EditIcon from '@material-ui/icons/Edit'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Layout from '../core/Layout';
import { sendComment, addemail, addstatus } from '../util';
import { TextareaAutosize, TextField } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const AcceptDecline = ({applicant}) =>{
   
   
    const [display,setDisplay] = useState('comment')
    const history = useHistory()
    const validationSchema = Yup.object({
        decission: Yup.string().required('Enter your Eamil'),
        comment: Yup.string().required('Password is Required')
    })

    const {handleSubmit, handleChange, handleBlur, setValues,  values, touched, errors } = useFormik({ 
        initialValues: {
            decission:'',
            note: '',
            subject_to:'',
            congrat: '1',
            pledge: '1',
            regret: '1',
            email: applicant.email ,
            cc:'',
            subject: '',
            body:'',
            loading: false,
            redirectToReferrer: false
        },


        
        validationSchema
    });
    const { decission, note,subject_to, congrat, pledge, regret, cc, email,subject, body, loading, error, redirectToReferrer } = values;	
    
    const  notEmpty = (obj) => {
        
        for (var key in obj) {
            if (obj[key] == ""){
                alert(`Select File for ${key}`)
                return false;
            }
        }
        return true;
    }
    const  isError = obj => {
        for (var key in obj) {
            if (obj[key])
                return obj[key];
        }
        return false;
    }
    

    const noError = (data, err )=>{

     /*  if (notEmpty(data))  {         
            setValues({ ...values, error: `Enter Regisration Data`, success: false });
            return false 
        } */
        if (isError(err)) {
            setValues({ ...values, error: isError(err) , success: false });
            return  false       
        }
        setValues({...values, error: '' });
        return true;
        //setValues({ ...values, error: false  });

    }
    const changestatus = (status)=> {
        setValues({...values, loading: true });        
        addstatus(status).then(data => {
            if (data.error) {
                alert(data.error)
            } else {
                setValues({...values, loading: false });
                let s = status.status_id == '1' ? 'accepted' : 'declined'
                alert(`${applicant['First Name']} ${applicant['Last Name']} ${s}`)
                //window.location.reload();               
            }
        });
}
    
    const sendEmail = ()=> {
        setValues({ ...values, error: false, loading: true });
        addemail( email,cc,subject,body ).then(data => {
            if (data.error) {
                alert(data.error)
            } else {
             alert('Email Sent')
             setValues({...values, loading: false });
            }
        });
    }
    const showLoading = () =>
    loading && (
        <div className="alert alert-info">
            <h2>Loading...</h2>
        </div>
    );

   /* const showNotes = () =>{
        return (<Container   className="alert alert-info form-label">  
       { applicant.status.map(item => (
            
             (((item.status === "Declined") || (item.status === "Approved")) ?
                <div> {item.status}  at {(item.status_date).substring(0,10)} for the reason { item.note}</div> : null)
            
        ))} 

    </Container> )
    } */
   
    const Decision = ()=>{
        const [open, setOpen] = React.useState(false);
        const handleClickOpen = (status) => {
            setOpen(true);
           
          };
        
          const handleClose = () => {
            setOpen(false);
          }
        return (
            <Container maxWidth="xs">
                <h2>{applicant['First Name']} {applicant['Last Name']}</h2>
                <form >
                <div className="form-group" style={{ marginTop: 20}}>
                        <label className="form-label">Accept or Decline</label>                    
                     <div className="form-group">
                     <div>
                        <input type="radio" id="accept" name="decission" onChange={handleChange}  value="1"/>
                        <label className="form-label" style={{marginRight: 20}}>Accept</label>
                        <input type="radio" id="decline" name="decission" onChange={handleChange}  value="2"/>
                        <label className="form-label">Decline</label>  
                        { errors.gender ? errors.decission : null} 
                     </div>
                    </div> 
                    </div>
                    { decission != undefined && decission == 1 &&
                    <div>
                        <div className="form-group">
                            <label className="form-label">Reason for Accept</label> 
                            <textarea key="note" id="note" name="note" className="form-control" onBlur={handleBlur} onChange={handleChange} type="text" value={note}  /> 
                            { touched.note && errors.note ? <div  className="alert alert-danger">  { errors.note} </div> : null} 
                            <div className="form-group">
                            <label className="form-label">Subject to </label> 
                            <textarea key="subject_to" id="subject_to" name="subject_to" className="form-control" onBlur={handleBlur} onChange={handleChange} type="text" value={subject_to}  /> 
                            { touched.subject_to && errors.subject_to ? <div  className="alert alert-danger">  { errors.subject_to} </div> : null} 
                        </div> 
                        </div>
                                       
                        <input id="acc" type="button" value="Save" onClick={ ()=> { handleClickOpen({first_name: applicant["First Name"], last_name: applicant["Last Name"], case_status: 1 }); }}   
                                                             className="btn-success form-control btn-next"  style={{marginTop: 10}}/>
                            
                    </div>
                    
                    }
                    { decission != undefined && decission == 2 &&
                    <div>
                        <div className="form-group">
                            <label className="form-label">Reason for Decline</label> 
                            <textarea key="note" id="note" name="note" className="form-control" onBlur={handleBlur} onChange={handleChange} type="text" value={note}  /> 
                            { touched.note && errors.note ? <div  className="alert alert-danger">  { errors.note} </div> : null} 
                        </div> 
                        <div className="form-group">
                            <label className="form-label">Subject to </label> 
                            <textarea key="subject_to" id="subject_to" name="subject_to" className="form-control" onBlur={handleBlur} onChange={handleChange} type="text" value={subject_to}  /> 
                            { touched.subject_to && errors.subject_to ? <div  className="alert alert-danger">  { errors.subject_to} </div> : null} 
                        </div> 
                        <input id="dec" type="button" value="save" onClick={ ()=> {handleClickOpen({first_name: applicant["First Name"], last_name: applicant["Last Name"], case_status: 0 }); }} className="btn-success form-control btn-next"  style={{marginTop: 10}} style={{marginTop: 10}}/>
                          
                    </div>  }

                   
                </form>




                <div>
       
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"SANZAF bursary"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Do you want to {  decission == 1 ? 'accept' : decission == 2 ? 'decline' : null} { applicant['First Name']} { applicant['Last Name']} ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>No</Button>
                <Button onClick={()=> {handleClose(); changestatus({application_id: applicant.application_id, note , subject_to, status_id:  decission == 1 ? '1' : decission == 2 ? '0' : null });}} autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </div>
             
             </Container  >
        )
    }
    
    const DisplayForm = () =>{
        switch(display){
            case 'comment':
                return Decision()
         
        }
    }
    return(
        <div>
            <form>
                 { /*showNotes()*/ }
                 <button onClick={ ()=> window.location.reload() }>Back to applicant page</button>
                 <Container maxWidth="xs" >
                 {showLoading()}
                 </Container>
                {Decision()}
                     
            </form>
        </div>
    )
    
}
export default AcceptDecline;