import axios from 'axios'


export const register = user => {
    return fetch('/api/addapplicant', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    })
        .then(response => {
            return response.json(); })
};
export const registerApplication = app => {
    const application_id =  JSON.parse(localStorage.getItem('application_id'))
    const applicant_id =  JSON.parse(localStorage.getItem('log')).user
    app.application_id = application_id
    app.applicant_id = applicant_id
    return fetch('/api/addapplication', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token            
        },
        body: JSON.stringify(app)
    })
        .then(response => {
            return response.json(); })
}; 



export const getApplicantData = (application_year) => {
    const application_id =  JSON.parse(localStorage.getItem('application_id'))
    const applicant_id =  JSON.parse(localStorage.getItem('log')).user
   // app.application_id = application_id
   // app.applicant_id = applicant_id
    return fetch(`/api/getapplicantdata/${applicant_id}/${application_year}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },       
    })
        .then(response => {
            return response.json(); })
};

export const getApplicant = () => {
    const application_id =  JSON.parse(localStorage.getItem('application_id'))
    const applicant_id =  JSON.parse(localStorage.getItem('log')).user
   // app.application_id = application_id
   // app.applicant_id = applicant_id
    return fetch(`/api/getapplicant/${applicant_id}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },       
    })
        .then(response => {
            return response.json(); })
};
export const fechData = () => {
    const application_id =  JSON.parse(localStorage.getItem('application_id'))
    const applicant_id =  JSON.parse(localStorage.getItem('log')).user
   // app.application_id = application_id
   // app.applicant_id = applicant_id
    return fetch(`/api/fechdata/${applicant_id}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },       
    })
        .then(response => {
            return response.json(); })
};

export const getStatus = () => {
    const application_id =  JSON.parse(localStorage.getItem('application_id'))
    const applicant_id =  JSON.parse(localStorage.getItem('log')).user
   // app.application_id = application_id
   // app.applicant_id = applicant_id
    return fetch(`/api/getapplicationsatus/${application_id}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },       
    })
        .then(response => {
            return response.json(); })
};


export const getApplication = () => {
    const application_id =  JSON.parse(localStorage.getItem('application_id'))
    const applicant_id =  JSON.parse(localStorage.getItem('log')).user
   // app.application_id = application_id
   // app.applicant_id = applicant_id
    return fetch(`/api/getapplication/${application_id}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },       
    })
        .then(response => {
            return response.json(); })
};


    

export const downloadPledgeLetter1 = (pledgeData) => {
    return fetch('/api/downloadpledgeletter', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },
        body: JSON.stringify(pledgeData)
    })
        .then(response => {
            return response.json(); })
}


export const sendresults = (applicant) => {
    const application_id =  JSON.parse(localStorage.getItem('application_id'))
    const applicant_id =  JSON.parse(localStorage.getItem('log')).user
    return fetch('/api/sendresults', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },
        body: JSON.stringify(applicant)
    })
        .then(response => {
            return response.json(); })
}


export const sendDocsCompleted = (applicant) => {
    const application_id =  JSON.parse(localStorage.getItem('application_id'))
    const applicant_id =  JSON.parse(localStorage.getItem('log')).user
    return fetch('/api/sendocscompleted', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },
        body: JSON.stringify(applicant)
    })
        .then(response => {
            return response.json(); })
}

export const sendAcceptDecline = (applicant) => {
    const application_id =  JSON.parse(localStorage.getItem('application_id'))
    const applicant_id =  JSON.parse(localStorage.getItem('log')).user
    return fetch('/api/sendacceptdecline', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },
        body: JSON.stringify(applicant)
    })
        .then(response => {
            return response.json(); })
}



export const sendpledgeletter = (applicant) => {
    const application_id =  JSON.parse(localStorage.getItem('application_id'))
    const applicant_id =  JSON.parse(localStorage.getItem('log')).user
    return fetch('/api/sendpledgeletter', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },
        body: JSON.stringify(applicant)
    })
        .then(response => {
            return response.json(); })
}


export const appsubmit = () => {
    const application_id =  JSON.parse(localStorage.getItem('application_id'))
    const applicant_id =  JSON.parse(localStorage.getItem('log')).user
    const status = { application_id}
    return fetch('/api/appsubmit', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },
        body: JSON.stringify(status)
    })
        .then(response => {
            return response.json(); })
}

export const addstatus = (status) => {
     return fetch('/api/addstatus', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },
        body: JSON.stringify(status)
    })
        .then(response => {
            return response.json(); })
}

export const deletePayment = (payment) => {
    return fetch('/api/deletepayment', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },
        body: JSON.stringify(payment)
    })
        .then(response => {
            return response.json(); })
}
export const addPayment = (payment) => {
    return fetch('/api/addpaymet', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },
        body: JSON.stringify(payment)
    })
        .then(response => {
            return response.json(); })
}





export const sendconfirmation = () => {
    const application_id =  JSON.parse(localStorage.getItem('application_id'))
    const applicant_id =  JSON.parse(localStorage.getItem('log')).user
    const status = { application_id, applicant_id}
    return fetch('/api/submissionconfirmation', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },
        body: JSON.stringify(status)
    })
        .then(response => {
            return response.json() })
}

export const getapplicantjsondata = () => {
    const application_id =  JSON.parse(localStorage.getItem('application_id'))
    const applicant_id =  JSON.parse(localStorage.getItem('log')).user
    const status = { application_id, applicant_id}
    return fetch('/api/getapplicantjsondata', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },
        //body: JSON.stringify(status)
    })
        .then(response => {
            return response.json()})
}




export const applicationreport = () => {
    const application_id =  JSON.parse(localStorage.getItem('application_id'))
    const applicant_id =  JSON.parse(localStorage.getItem('log')).user
    const status = { application_id, applicant_id}
    return fetch('/api/aplicantionreport', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },
        //body: JSON.stringify(status)
    })
        .then(response => {
            return response.json()})
}


export const searchApplication = (searchKeys) => {
    return fetch('/api/searchapplication', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },
        body: JSON.stringify(searchKeys)
    })
        .then(response => {
            return response.json()})
}



export const updateApplicantDataAdmin = data => {
    //const email= JSON.parse(localStorage.getItem('log')).email
    //const applicant_id =  JSON.parse(localStorage.getItem('log')).user
    //const data = {applicant: applicant, email, applicant_id} 
    return fetch('/api/updateapplicant', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            return response.json(); })
}
export const updateApplicantationDataAdmin = app => {
    //const application_id =  JSON.parse(localStorage.getItem('application_id'))
    //const applicant_id =  JSON.parse(localStorage.getItem('log')).user
    //const data = {application: app, application_id: application_id} 
    //app.application_id = applicant_id
    return fetch('/api/updateapplication', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },
        body: JSON.stringify(app)
    })
        .then(response => {
            return response.json(); })
}


export const updateApplicantData = applicant => {
    const email= JSON.parse(localStorage.getItem('log')).email
    const applicant_id =  JSON.parse(localStorage.getItem('log')).user
    const data = {applicant: applicant, email, applicant_id} 
    return fetch('/api/updateapplicant', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            return response.json(); })
}

export const updateApplicantationData = app => {
    const application_id =  JSON.parse(localStorage.getItem('application_id'))
    const applicant_id =  JSON.parse(localStorage.getItem('log')).user
    const data = {application: app, application_id: application_id} 
    //app.application_id = applicant_id
    return fetch('/api/updateapplication', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            return response.json(); })
}


export const uploadFiles = (application_id, file,filename,results, onUploadProgress ) => {   
    //const application_id =  JSON.parse(localStorage.getItem('application_id'))
    //const applicant_id =  JSON.parse(localStorage.getItem('log')).user
    //const data1 = {file: item, application_id: application_id} 
    const formdata = new FormData()
    formdata.append('file',file )
    formdata.append('filename', filename)
    formdata.append('application_id', application_id )
    formdata.append('results', JSON.stringify(results) )
    return axios.post('/api/updateapplicationfiles', formdata, {
            headers: {
              "Content-Type": "multipart/form-data"
              //Authorization: JSON.parse(localStorage.getItem('log')).token
            },
            onUploadProgress,
          });
    }
export const uploadResults = (resultsData, onUploadProgress ) => {

    const formdata = new FormData()
    formdata.append('application_id', resultsData.application_id )
    formdata.append('file',resultsData.resultFileToUpload['fileObject'] )
    formdata.append('fileName', resultsData.resultFileToUpload['name'])    
    formdata.append('results', JSON.stringify(resultsData.results) )
    console.log(formdata)
    return axios.post('/api/submitresults', formdata, {
            headers: {
                "Content-Type": "multipart/form-data"
                //Authorization: JSON.parse(localStorage.getItem('log')).token
            },
            onUploadProgress,
            });
    }

export const uploadDocs = (applicant_id, file,filename, onUploadProgress ) => {   
    //const application_id =  JSON.parse(localStorage.getItem('application_id'))
    //const applicant_id =  JSON.parse(localStorage.getItem('log')).user
    //const data1 = {file: item, application_id: application_id} 
    const formdata = new FormData()
    formdata.append('file',file )
    formdata.append('filename', filename)
    formdata.append('applicant_id', applicant_id )
    return axios.post('/api/updateapplicationfiles', formdata, {
            headers: {
                "Content-Type": "multipart/form-data",
                //Authorization: JSON.parse(localStorage.getItem('log')).token
            },
            onUploadProgress,
            });
    }

export const uploadResultFiles = (file,filename,token, onUploadProgress ) => {   
    const application_id =  JSON.parse(localStorage.getItem('application_id'))
    const applicant_id =  JSON.parse(localStorage.getItem('log')).user
    //const data1 = {file: item, application_id: application_id} 
    const formdata = new FormData()
    formdata.append('file',file )
    formdata.append('filename', filename)
    formdata.append('token', token )
    return axios.post('/api/uploadresultfile', formdata, {
            headers: {
                "Content-Type": "multipart/form-data",
                
            },
            onUploadProgress,
            });
}

export const getResultsData = (application_id ) => {  
     return fetch(`/api/getresultsdata?application_id=${application_id}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },       
    })
        .then(response => {
            return response.json(); })
};

export const getAllResults = (filter) => {  
    return fetch(`/api/getallresults`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },
        body: JSON.stringify(filter)  
   })
       .then(response => {
           return response.json(); })
};
  

export const confirmResult = (result) => {  
    return fetch(`/api/confirmresult`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },
        body: JSON.stringify(result)  
   })
       .then(response => {
           return response.json(); })
};    

export const downloadfilefolder = (applicant_id ) => { 
    return axios.get(`/api/downloadapplicantfolder?applicant_id=${applicant_id}`, {
                responseType: 'blob',
                headers: {
                    Authorization: JSON.parse(localStorage.getItem('log')).token,
                    'Cache-Control': 'no-cache, no-store, must-revalidate'                   
                 }           
            });
    }


export const downloadFormChecklist = (application_id,filename ) => { 
    return axios.get(`/api/getformchecklistfile?application_id=${application_id}&filename=${filename}`, {
                responseType: 'blob',
                headers: {
                    Authorization: JSON.parse(localStorage.getItem('log')).token,
                    'Cache-Control': 'no-cache, no-store, must-revalidate' 
                  
                 }           
            });
    }
    


export const downloadSupportingDoc = (application_id,filename,documentType ) => { 
    return axios.get(`/api/downloadsupportingdoc?application_id=${application_id}&filename=${filename}&doctype=${documentType}`, {
                responseType: 'blob',
                headers: {
                    Authorization: JSON.parse(localStorage.getItem('log')).token,
                    'Cache-Control': 'no-cache, no-store, must-revalidate' 
                  
                 }           
            });
    }


export const downloadFileAdmin = (application_id,filename ) => { 
    return axios.get(`/api/getapplicantfileadmin?application_id=${application_id}&filename=${filename}`, {
                responseType: 'blob',
                headers: {
                    Authorization: JSON.parse(localStorage.getItem('log')).token,
                    'Cache-Control': 'no-cache, no-store, must-revalidate' 
                  
                 }           
            });
    }

export const downloadPledgeLetter = (applicant_id ) => {   
        return axios.get(`/api/downloadpledgeletter?applicant_id=${applicant_id}`, {
                responseType: 'blob',
                //body: JSON.stringify(pledgeData),
                headers: {
                    Authorization: JSON.parse(localStorage.getItem('log')).token,
                    Accept: 'application/json',
                    'Cache-Control': 'no-cache, no-store, must-revalidate'              
                }  
            });
    }    
export const downloadPledgeLetter_get = (application_id,filename,documentType ) => { 
    return axios.get(`/api/downloadpledgeletter`, {
                responseType: 'blob',
                headers: {
                    Authorization: JSON.parse(localStorage.getItem('log')).token,
                    'Cache-Control': 'no-cache, no-store, must-revalidate' 
                    
                    }           
            });
    }
    
export const downloadFile = (application_id,filename ) => {   
    return axios.get(`/api/getapplicantfile?application_id=${application_id}&filename=${filename}`, {
            responseType: 'blob',
            headers: {
                Authorization: JSON.parse(localStorage.getItem('log')).token,
                'Cache-Control': 'no-cache, no-store, must-revalidate'              
            }  
        });
    }
    
export const downloadReport = (applicant_id ) => {   
    return axios.get(`/api/getapplicantreport?applicant_id=${applicant_id}`, {
            responseType: 'blob',
            headers: {
                Authorization: JSON.parse(localStorage.getItem('log')).token,
                'Cache-Control': 'no-cache, no-store, must-revalidate'              
            }  
        });
    }

export const getApplicationFiles = ( onDownloadProgress ) => {   
    const application_id =  JSON.parse(localStorage.getItem('application_id'))
    const applicant_id =  JSON.parse(localStorage.getItem('log')).user
    //const data1 = {file: item, application_id: application_id} 
    const formdata = new FormData()
    formdata.append('applicant_id', applicant_id )
    return axios.get('/api/getapplicationfiles', {
            onDownloadProgress,
            });
    }
    




export const sendComment = (commentObject  ) => {
    return fetch('/api/addcomment', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },
        body: JSON.stringify(commentObject)
    })
        .then(response => {
            return response.json(); })
};




export const sendGeneralEmails = (data) => { 
    
    return fetch('/api/sendgeneralemails', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },
        body: JSON.stringify(data)
        
    })
        .then(response => {
            return response.json(); })
};

export const sendDocumentsRequest = (data) => {
    return fetch('/api/senddocumentsrequest', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },
        body: JSON.stringify(data)        
    }).then(response => {
        return response.json(); })
};

export const sendResultsRequest = (data) => { 
    
    return fetch('/api/sendresultrequest', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },
        body: JSON.stringify(data)
        
    })
        .then(response => {
            return response.json(); })
};
export const sendLateApplicationRequest = (data) => { 
    
    return fetch('/api/sendlateapplicationrequest', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            return response.json(); })
};



export const getLookUp1 = () => {
    const application_id =  JSON.parse(localStorage.getItem('application_id'))
    const applicant_id =  JSON.parse(localStorage.getItem('log')).user
   // app.application_id = application_id
   // app.applicant_id = applicant_id
    return fetch('/api/getLookUp', {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
            //Authorization: JSON.parse(localStorage.getItem('log')).token
        },       
    })
        .then(response => {
            return response.json(); })
};



export const getLookUp = () => {
    //const application_id =  JSON.parse(localStorage.getItem('application_id'))
    //const applicant_id =  JSON.parse(localStorage.getItem('log')).user
    const status = { 'application_id':'test', 'applicant_id':'test'}
    return fetch('/api/getLookUp', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
            //Authorization: JSON.parse(localStorage.getItem('log')).token
        },
        body: JSON.stringify(status)
    })
        .then(response => {
            return response.json()})
}





export const addemail = (email, cc , subject, body) => {
    const emailobject = {
        email, cc, subject, body
    }
    return fetch('/api/sendemailcomment', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },
        body: JSON.stringify(emailobject)
    })
        .then(response => {
            return response.json(); })
};

export const sendAllAccepted = () => {
    return fetch('/api/sendallaccepted', {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        }
        //body: JSON.stringify(emailobject)
    })
        .then(response => {
            return response.json(); })
};


export const sendAllDeclined = () => {
   
    return fetch('/api/sendalldeclined', {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },
        //body: JSON.stringify(emailobject)
    })
        .then(response => {
            return response.json(); })
};


    
export const signin = user => {
    return fetch('/api/login', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    })
        .then(response => {
            return response.json();
        })
      
};

export const passwordhandle = user => {
    return fetch('/api/passwordchange', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    })
        .then(response => {
            return response.json();
        })
       ;
};


export const resetpassword = (user ) => {
    return fetch("/api/handlepasswordchange/" + user.token , {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            
        },
        body: JSON.stringify(user)
    })
        .then(response => {
            return response.json();
        })
      
};



export const signout = next => {
    if (typeof window !== 'undefined') {
        
        fetch('/api/logout', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: JSON.parse(localStorage.getItem('log')).token
                
            }
            //body: localStorage.getItem('log')
           }
            )
            .then(response => {
                return response.json()
            })
            localStorage.removeItem('log');
            localStorage.removeItem('application_id');
            localStorage.removeItem('application_status');
            localStorage.clear();
            next();
    }
};

export const isAuthenticated = () => {
    if (typeof window == 'undefined') {
        return false;
    }
    if (localStorage.getItem('log')) {
        return JSON.parse(localStorage.getItem('log'));
    } else {
        return false;
    }
};


export const authenticate = (data, next) => {
    if (typeof window !== 'undefined') {
        const  user = data.user
        localStorage.setItem('log', JSON.stringify(data));      
        if (data['application_id'] != '')
             localStorage.setItem('application_id', JSON.stringify(data['application_id']));
        next();
    }
};


export const addResults = (subjects) => {
    return fetch('/api/addresults', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: JSON.parse(localStorage.getItem('log')).token
        },
        body: JSON.stringify(subjects)
    })
        .then(response => {
            return response.json(); })
}
