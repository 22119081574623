
export const list_of_study_levels = [
    'Diploma',
    'National Diploma',
    'Advanced Diploma',
    'Higher Certificate',
    'Advanced Certificate',
    'Post Graduate Diploma',
    'Post Graduate Certificate',
    'PGCE',
    'Bachelor',
    'BTech',
    'Honours',
    'Masters',
    'Doctoral',
    'PhD'   
]

export const list_of_categories = [
    'testitem'
]

export const list_how_learn_about = [
    'SANZAF Website',
    'Conventional Media',
    'Social Media',
    'University Website',
    'Poster',
    'Word of mouth through family or friend',
    'Other'
]

export const list_of_provinces = [ 'Eastern Cape',
'Free State',
'Gauteng',
'KwaZulu-Natal',
'Limpopo' ,
'Mpumalanga',
'Northern Cape',
'North West',
'Western Cape'
]                  

export const list_of_races = [
    'African',
    'Coloured',
    'Malay',
    'Indian',
    'White',
    'Other',
 ]

export const list_of_religion = [ 'Islam',

'Other']

export const list_of_status = ['created',
'Submited',
'Approved',
'Declined',
'Under Review'
]
export const list_of_referenceRelationship = [ 
    'Religious Leader',
    'University/School',    
]


export const list_of_countries = ['Afghanistan',
'Albania',
'Algeria',
'Angola',
'Argentina',
'Armenia',
'Australia',
'Austria',
'Azerbaijan',
'Bahamas',
'Bahrain',
'Bangladesh',
'Belgium',
'Benin',
'Bhutan',
'Bosnia and Herzegovina',
'Botswana',
'Brazil',
'Brunei Darussalam',
'Bulgaria',
'Burkina Faso',
'Burundi',
'Cambodia',
'Cameroon',
'Canada',
'Cape Verde',
'Central African Republic',
'Chad',
'China',
'Comoros',
'Congo',
'Congo, the Democratic Republic of the',
'Cote d ivoire',
'Croatia',
'Cuba',
'Denmark',
'Djibouti',
'Egypt',
'Equatorial Guinea',
'Eritrea',
'Ethiopia',
'Finland',
'France',
'Gabon',
'Gambia',
'Germany',
'Ghana',
'Guinea',
'Guinea-Bissau',
'India',
'Indonesia',
'Iran',
'Iraq',
'Ireland',
'Israel',
'Italy',
'Jamaica',
'Japan',
'Jordan',
'Kazakhstan',
'Kenya',
'Korea, Democratic Peoples Republic of',
'Korea, Republic of',
'Kuwait',
'Kyrgyzstan',
'Lebanon',
'Lesotho',
'Liberia',
'Libya',
'Madagascar',
'Malawi',
'Malaysia',
'Maldives',
'Mali',
'Mauritania',
'Mauritius',
'Mexico',
'Morocco',
'Mozambique',
'Myanmar',
'Namibia',
'Nauru',
'Nepal',
'Netherlands',
'New Zealand',
'Niger',
'Nigeria',
'Norway',
'Oman',
'Pakistan',
'Palau',
'Palestine',
'Panama',
'Paraguay',
'Peru',
'Philippines',
'Poland',
'Portugal',
'Qatar',
'Reunion',
'Romania',
'Russian',
'Rwanda',
'Saudi Arabia',
'Senegal',
'Seychelles',
'Sierra Leone',
'Singapore',
'Somalia',
'South Africa',
'Spain',
'Sri Lanka',
'Sudan',
'Suriname',
'Swaziland',
'Sweden',
'Switzerland',
'Syrian',
'Taiwan',
'Tajikistan',
'Tanzania',
'Thailand',
'Togo',
'Tunisia',
'Turkey',
'Turkmenistan',
'Uganda',
'Ukraine',
'United Arab Emirates',
'United Kingdom',
'United States',
'Uruguay',
'Uzbekistan',
'Venezuela',
'Viet Nam',
'Yemen',
'Zambia',
'Zimbabwe'
]

export const list_of_languages = [
'Afrikaans',
'Albanian - shqip',
'Amharic - አማርኛ',
'Arabic - العربية',
'Bengali - বাংলা',
'Chinese - 中文',
'Croatian - hrvatski',
'Dutch - Nederlands',
'English',
'Finnish - suomi',
'French - français',
'German - Deutsch',
'German (Germany) - Deutsch (Deutschland)',
'Greek - Ελληνικά',
'Guarani',
'Gujarati - ગુજરાતી',
'Hausa',
'Hebrew - עברית',
'Hindi - हिन्दी',
'Indonesian - Indonesia',
'IsiXhosa',
'Sesotho',
'Italian - italiano',
'IsiNdebele',
'Setswana',
'SiSwati',
'Tshivenda',
'Xitsonga',
'Kannada - ಕನ್ನಡ',
'Kazakh - қазақ тілі',
'Khmer - ខ្មែរ',
'Korean - 한국어',
'Kurdish - Kurdî',
'Kyrgyz - кыргызча',
'Lao - ລາວ',
'Lingala - lingála',
'Malay - Bahasa Melayu',
'Malayalam - മലയാളം',
'Marathi - मराठी',
'Mongolian - монгол',
'Nepali - नेपाली',
'Oriya - ଓଡ଼ିଆ',
'Oromo - Oromoo',
'Pashto - پښتو',
'Persian - فارسی',
'Portuguese - português',
'Punjabi - ਪੰਜਾਬੀ',
'Romanian - română',
'Russian - русский',
'Shona - chiShona',
'Sindhi',
'Sinhala - සිංහල',
'Somali - Soomaali',
'Southern Sotho',
'Spanish - español',
'Swahili - Kiswahili',
'Swedish - svenska',
'Tajik - тоҷикӣ',
'Tamil - தமிழ்',
'Tatar',
'Telugu - తెలుగు',
'Thai - ไทย',
'Tigrinya - ትግርኛ',
'Tongan - lea fakatonga',
'Turkish - Türkçe',
'Turkmen',
'Twi',
'Ukrainian - українська',
'Urdu - اردو',
'Uyghur',
'Uzbek - o‘zbek',
'Walloon - wa',
'Welsh - Cymraeg',
'Western Frisian',
'Xhosa',
'Yiddish',
'Yoruba - Èdè Yorùbá',
'Zulu - isiZulu'
]

export const list_of_employments = [
    'Employed',
    'Self-employed',
    'Unemployed' 
]

export const list_of_maritals = [
    'Married',
    'Never-married',
    'Divorced/Separated' 
]

export const list_of_application_types = [
    'First time applicant (matriculant)',
    'First time applicant (currently at Tertiary Institution)',
    'Sponsored by SANZAF previously'
]

export const list_of_universities = [
    'IPSA (International Peace College South Africa)',
	'CPUT (Cape Peninsula University of Technology)',
	'CUT (Central University of Technology)',
	'DUT (Durban University of Technology)',
	'MATIES (University of Stellenbosch)',
	'MEDUNSA (Medical University of Southern Africa)',
	'MUT (Mangosuthu University of Technology)',
	'NMMU (Nelson Mandela Metropolitan University)',
	'NWU (North West University)',
	'RU (Rhodes University)',
	'TUKS (University of Pretoria)',
	'TURFLOOP (University of Limpopo)',
	'TUT (Tshwane University of Technology)',
	'UCT (University of Cape Town)',
	'UFH (University of Fort Hare)', 
	'UFS (University of the Free State)',
	'UJ (University of Johannesburg)', 
	'UKZN (University of KwaZulu Natal)', 
	'UNISA (University of South Africa)', 
	'UNIVEN (University of Venda)', 
	'UniZulu (University of Zululand)', 
	'UWC (University of the Western Cape)', 
	'VUT (Vaal University of Technology)', 
	'WITS (University of Witwatersrand)', 
	'WSU (Walter Sisulu University)',
    'MANCOSA',
    'Boston City Campus & Business College',
    'Cornerstone Institute',
    'Damelin',
    'College of Cape Town',
    'Varsity College',
    'Northlink College',
    'Pearson Institute for Higher Education',
    'Rosebank College',
    'South African College of Applied Psychology',
    'Two Oceans Graduate Institute',
    'Western Cape College of Nursing', 
	'Other'
]
export const list_of_IPSA_courses = [
    'Higher Certificate in Islamic Studies (HCIS)',
    'Bachelor of Arts Islamic Studies (BAIS)',
    'BA Honours Degree in Islamic Studies (BAHIS)',
    'Masters in Applied Islamic Thought (MAIT)',
]
export const paymentProfile = [
    {
        'name': 'UWC (University of Western Cape)',
        'payment_info': {
            'bank': 'ABSA',
            'account_no': '4049604740',
            'branch_no': '632005'        
        } 
   },
   {
        'name': 'CPUT(Cape Peninsula University of Technology)',
        'payment_info': {
            'bank': 'ABSA',
            'account_no': '4053548487',
            'branch_no': '632005'        
        } 
   },
   {
        'name': 'UCT(University of Cape Town)',
        'payment_info': {
            'bank': 'Standard Bank',
            'account_no': '270689982',
            'branch_no': '025009'        
        } 
    },
    {
        'name': 'MATIES (University of Stellenbosch)',
        'payment_info': {
            'bank': 'Standard Bank',
            'account_no': '63163225',
            'branch_no': '50610'        
        } 
    },
   {
        'name': 'UNISA(University of South Africa)',
        'payment_info': {
            'bank': 'FNB',
            'account_no': '62799625200',
            'branch_no': '250645'        
        } 
    },
    {
        'name': 'North West University)',
        'payment_info': {
            'bank': 'Standard Bank',
            'account_no': '330384465',
            'branch_no': '052838'        
        } 
    },
    {
        'name': 'College of Cape Town',
        'payment_info': {
            'bank': 'ABSA',
            'account_no': '4061794226',
            'branch_no': '632005'        
        } 
    },
    {
        'name': 'Two Oceans Graduate Institute',
        'payment_info': {
            'bank': 'ABSA',
            'account_no': '4090279744',
            'branch_no': '632005'        
        } 
    },
    {
        'name': 'CTU Training Solutions',
        'payment_info': {
            'bank': 'ABSA',
            'account_no': '9145400025',
            'branch_no': '632005'        
        } 
    },
    {
        'name': 'Kovacs Student Housing',
        'payment_info': {
            'bank': 'Standard Bank',
            'account_no': '220734526',
            'branch_no': '019205'        
        } 
    },
    {
        'name': 'Western Cape College of Cape Town',
        'payment_info': {
            'bank': 'Nedbank Bank',
            'account_no': '1452054975',
            'branch_no': '145209'        
        } 
    },
    {
        'name': 'South African College of Applied Psychology',
        'payment_info': {
            'bank': 'ABSA',
            'account_no': '4058824959',
            'branch_no': '632005'        
        } 
    },
    {
        'name': 'Edutel',
        'payment_info': {
            'bank': 'ABSA',
            'account_no': '4070153037',
            'branch_no': '630156'        
        } 
    },
    {
        'name': 'Regent Business School',
        'payment_info': {
            'bank': 'Standard Bank',
            'account_no': '051245485',
            'branch_no': '04262610'        
        } 
    },
    {
        'name': 'SAE Institute',
        'payment_info': {
            'bank': 'Standard Bank',
            'account_no': '050909622',
            'branch_no': '040026'        
        } 
    },
    {
        'name': 'Boston City Campus & Business College',
        'payment_info': {
            'bank': 'Standard Bank',
            'account_no': '001826549',
            'branch_no': '004305'        
        } 
    },
    {
        'name': 'Rosebank College',
        'payment_info': {
            'bank': 'ABSA',
            'account_no': '4052265438',
            'branch_no': '331155'        
        } 
    },
    {
        'name': 'Cornerstone Institute',
        'payment_info': {
            'bank': 'Nedbank',
            'account_no': '1075030269',
            'branch_no': '198765'        
        } 
    },
    {
        'name': 'MANCOSA Business College',
        'payment_info': {
            'bank': 'ABSA',
            'account_no': '4068456934',
            'branch_no': '634926'        
        } 
    },
    {
        'name': 'Varsity College',
        'payment_info': {
            'bank': 'ABSA',
            'account_no': '4052259471',
            'branch_no': '420510'        
        } 
    },
    {
        'name': 'Two Oceans Aquarium Education Foundation',
        'payment_info': {
            'bank': 'FNB',
            'account_no': '62856072675',
            'branch_no': '200610'        
        } 
    },
    {
        'name': 'Early Learning Foundation',
        'payment_info': {
            'bank': 'FNB',
            'account_no': '62034527989',
            'branch_no': '201909'        
        } 
    },
    {
        'name': 'Boland College',
        'payment_info': {
            'bank': 'ABSA',
            'account_no': '4078879219',
            'branch_no': '632005'        
        } 
    },
    {
        'name': 'Hugenote Kollege',
        'payment_info': {
            'bank': 'Nedbank',
            'account_no': '1156056861',
            'branch_no': '198765'        
        } 
    },
    {
        'name': 'Eduvos',
        'payment_info': {
            'bank': 'Standard Bank',
            'account_no': '1337440',
            'branch_no': '051001'        
        } 
    },
    {
        'name': 'Centre for Creartive Education',
        'payment_info': {
            'bank': 'Standard Bank',
            'account_no': '071793046',
            'branch_no': '025409'        
        } 
    }
]

export const list_of_courses = [
    "Accounting",
    "Accounting Science",
    "Accounting Theory",
    "Actuarial and Financial Mathematics",
    "Aeronautical Engineering",
    "Agricultural Economics/Agribusiness Management",
    "Agriculture",
    "Animal Science",
    "Animal Science / Pasture Science",
    "Applied Chemistry",
    "Applied Mathematics",
    "Applied Plant and Soil Sciences",
    "Architectural Technology",
    "Architectural Technology Applied Design",
    "Architectural Technology Management",
    "Architectural Technology Professional",
    "Architecture",
    "BTech IT",
    "BCom General",
    "BCur",
    "Biochemistry",
    "Biokinetics",
    "Biological and Agricultural Sciences",
    "Biological Sciences",
    "Biomedical Science",
    "Biomedical Technology",
    "Biotechnology",
    "Business Administration",
    "Business Education",
    "Business IT",
    "Business Management",
    "Chemical Engineering",
    "Chemistry",
    "Civil and Biosystems Engineering",
    "Civil Engineering",
    "Clinical Medical Practice",
    "Commerce  in Accounting",
    "Commercial Law",
    "Communication",
    "Communication Management",
    "Computer Engineering",
    "Computer Science",
    "Construction",
    "Construction Management",
    "Consumer Science Foods: Retail Management",
    "Consumer Science Hospitality Management",
    "Dental Science",
    "Dentistry",
    "Development studies",
    "Dietetics",
    "Drafting and Interpretation of Contracts",
    "Ecology",
    "Econometrics",
    "Economics",
    "Education",
    "Electrical Engineering",
    "Emergency Medical Care",
    "Entomology",
    "Entrepreneurship",
    "Environmental and Engineering Geology",
    "Environmental Health",
    "Environmental Sciences",
    "Finance",
    "Financial Accounting",
    "Financial Sciences",
    "Fine Art",
    "Food Management",
    "Food Science",
    "Food Science and Technology",
    "Food Technology",
    "Forensic Accounting",
    "BSc General",
    "BA General",
    "Genetics",
    "Geography",
    "Geoinformatics",
    "Geology",
    "Homeopthy",
    "Human Genetics",
    "Human Physiology",
    "Human Physiology Genetics and Psychology",
    "Human Resource Management",
    "Industrial Design",
    "Industrial Engineering",
    "Industrial Psychology",
    "Informatics",
    "Information Science",
    "Information Technology ",
    "Interior Architecture",
    "Interior Design",
    "International Relations",
    "International Relations and Psychology",
    "Investment Management",
    "Journalism",
    "Landscape Architecture",
    "Life and Environmental Sciences ",
    "LLB",
    "Marketing",
    "Marketing Management",
    "Mathematical Sciences",
    "Mathematics",
    "Mathematics Education",
    "Mechanical Engineering",
    "Mechatronics",
    "Medical Immunology",
    "Medical Sciences",
    "Medicine and Surgery",
    "Meteorology",
    "Microbiology",
    "Mining Engineering",
    "Nursing",
    "Nursing Science",
    "Nutrition",
    "Occupational Therapy",
    "Option Supply Chain Management",
    "Optometry",
    "Oral Hygiene",
    "PGCE Education",
    "Pharmacy",
    "Physical Sciences",
    "Physics",
    "Physiotherapy",
    "Plant Pathology",
    "Plant Science",
    "Podiatry",
    "Political Science",
    "Psychology",
    "Public Administration",
    "Quantity Surveying",
    "Radiography",
    "Radiography: Diagnostic",
    "Radiography: Therapy",
    "Radiography: Nuclear Medicine",
    "Radiography: Ultrasound ",
    "Recreation and Sport Management",
    "Social Work",
    "Somatology",
    "Speech and Hearing Therapy",
    "Speech Language Therapy",
    "Sport Commu​nication",
    "Sport Develop​ment",
    "Sport Manag​ement",
    "Sport​ Psychology",
    "Statistics",
    "Theology",
    "Tourism Management",
    "Town and Regional Planning",
    "Zoology",
    "OTHER"
]