import React, { useState } from 'react';
import {Link } from 'react-router-dom';
import  Container from '@material-ui/core/Container'
import Layout from '../core/Layout';
import { resetpassword } from '../util';

const PasswordRest = ({match:{params:{token}}}) => {
    const [values, setValues] = useState({
        password: '',
        re_password: '',
        error: '',
        success: false
    });
   
    const {  password, re_password, error, success} = values;

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
    };

    const clickSubmit = event => {
        event.preventDefault();
        setValues({ ...values, error: false });
        resetpassword({token , password} ).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, success: false });
            } else {
                setValues({ ...values, error: false, success: true });
            }
        });
    };

    const resetPasseordForm = () => (
        <form>
                   

            <div className="form-group" style={{marginTop: 100}}>
                <label className="text-muted">New Password</label>
                <input onChange={handleChange('password')} type="password" className="form-control" value={password} />
            </div>
            <div className="form-group">
                <label className="text-muted">Confirm New Password</label>
                <input onChange={handleChange('re_password')} type="password" className="form-control" value={re_password} />
            </div>
            <button onClick={clickSubmit} className="btn btn-primary" style={{marginTop: 20}}>
                ResetPassword
            </button>
        </form>
    );

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );
    const showSuccess = () => (
        <div className="alert alert-info" style={{ display: success ? '' : 'none' }}>
            Password changed successuflly . Please <Link to="/signin">Signin</Link>
        </div>
    );

    return (
        <Layout>
         <Container maxWidth="sm">
            {showError()}
            {showSuccess()}
            {resetPasseordForm()}
        </Container>
        </Layout>
    );
};

export default PasswordRest;
