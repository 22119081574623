
import React from 'react';
import Layout from './Layout';
import Signin from '../user/Signin'
import Signup from '../user/Signup'
import {Container} from '@material-ui/core'
import { Link, useHistory, withRouter } from "react-router-dom";
import jwt_decode from "jwt-decode";




const Home = () => { 
    var token = localStorage.getItem('log')
    try {
        var decode = jwt_decode.decode(token)
        if (Date.now >= decode.exp * 1000){
            localStorage.clear()
        }

    }
    catch(err){
        localStorage.clear()
    }
    return (
        <Layout>
             <Container>
                 <img style={{marginLeft: 0}} src="assets/img/sanzaf-banner.jpg" width="900" height="250"/>    
                  <Container  class="justify-content-center" style={{marginLeft: 0}}>
                    <div style={{marginTop: 5}} class="justify-content-center">
                    <h2 data-aos="fade-up" style={{marginLeft:0}}>South African National Zakah Fund 2025 Bursary Application  </h2>
                    <h2 style={{marginLeft: 200}}> Welcome to your future! </h2>
                    <div style={{marginTop: 50, fontSize: 20}} >
                    <p data-aos="fade-up" data-aos-delay="400">Western Cape Bursary Application Form </p>
                    <p data-aos="fade-up" data-aos-delay="400">This application form takes approximately 20 minutes to complete and must be submitted together <br/> with all the required documents before 31 December 2024. </p>
                    <p data-aos="fade-up" data-aos-delay="400">You will need the documents in PDF or IMAGE format before proceeding with the application process. </p>
                    <p data-aos="fade-up" data-aos-delay="400">Please have the following saved as individual and separate documents for upload:</p>
                    <p data-aos="fade-up" data-aos-delay="400">- Proof of Income or a certified affidavit of unemployment (of self/spouse/parents/guardian) </p>
                    <p data-aos="fade-up" data-aos-delay="400">- Certified Identity Documents</p>
                    <p data-aos="fade-up" data-aos-delay="400">- Motivational Letter</p>
                    <p data-aos="fade-up" data-aos-delay="400">- Testimonial from school, university or Imaam/Religious leader</p>
                    <p data-aos="fade-up" data-aos-delay="400">You will not be able to complete this application unless you have uploaded the required documents.</p>
                    <p data-aos="fade-up" data-aos-delay="400">Please ensure that each of your document files do not exceed 2MB and it must be either in PDF or IMAGE <br/> format.</p>
                    <p data-aos="fade-up" data-aos-delay="400">Failure to submit the required documents may result in your application being rejected.</p>
                    <p data-aos="fade-up" data-aos-delay="400"><strong>Note well: </strong> <br/>SANZAF offers partial funding towards payment of fees only. We therefore strongly encourage <br/> applicants to apply for other bursaries such as NSFAS.</p>
                </div>
                   </div> 
            </Container>
                
            
                    <div data-aos="fade-up" data-aos-delay="600"  class="hero d-flex">
                        <div style={{marginLeft: 180, marginTop: 60}} class="text-center text-lg-start justify-content-center">
                            <Link to="/signin" style={{marginRight: 50}} class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                                <span>Login</span>
                                <i class="bi bi-arrow-right"></i>
                            </Link>
                            <Link to="/register" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                                <span>Apply Now</span>
                                <i class="bi bi-arrow-right"></i>
                            </Link>
                            {/*<Link style={{marginLeft: 40}} to="/howtoapply"  target="_blank" rel="noopener noreferrer" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                                <span>How to Apply?</span>
                                <i class="bi bi-arrow-right"></i>
                           </Link>*/}
                        </div>
                    </div>
                </Container>
        </Layout>
       
           
    );
};
export default Home;
