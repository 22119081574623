import React, { useState , useEffect} from 'react';
import { Link, withRouter , useHistory} from "react-router-dom";

import {useForm, useStep } from 'react-hooks-helper'
import {Form, useFormik}  from 'formik'
import * as Yup from 'yup';
import  Container from '@material-ui/core/Container'
import FormLabel from '@material-ui/core/FormLabel'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Typography from '@material-ui/core/Typography'; 
import { makeStyles } from '@material-ui/core/styles';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetail from '@material-ui/core/AccordionDetails'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Layout from '../core/Layout';
import { sendComment, sendResultRequest } from '../util';
import { TextareaAutosize, TextField } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import Checkbox from '@material-ui/core/Checkbox';
import { read } from 'xlsx';
import { Tune } from '@material-ui/icons';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Uni from './Uni'
import GroupedByIncome from './GroupedByIncome' 
import GroupedByCourse from './GroupedByCourse' 
import GroupedStudyLevel from './GroupedStudyLevel' 

const Statistics = ({application}) =>{  
    const [redirect,setRedirect] = useState('home')
    let all
    let nonMuslim = 0, review = 0,pending=0, approved=0, declined=0, sa=0, nonsa=0  
    var matric=0, male=0, female=0, african=0, coloured=0, indian=0, white=0, foreign=0, race_other=0
    var groupBy = (array, predicate) => {
		var grouped = {}

		for(var i = 0; i < array.length; i++) {
			var groupKey = predicate(array[i]);
			if (typeof(grouped[groupKey]) === "undefined")
				grouped[groupKey] = [];
			grouped[groupKey].push(array[i]);
		}
	
		return grouped;
	}
    if (application != undefined){
        var groupData = groupBy(application,  (obj) => {
            return obj["University"];
        });	

    }	
    if (application != undefined){
        var raceDataObject = groupBy(application,  (obj) => {
            return obj["Race"];
        });	
    }
    if (application != undefined){
        var genderDataObject = groupBy(application,  (obj) => {
            return obj["Gender"];
        });	
    }


    if (application != undefined){
        
        all = application.length
        application.map( row => {
            if (row['Religion'] == 'Other'){
                nonMuslim = nonMuslim + 1
            }
            if (row['review'] !== ''){
                review = review + 1
            }
            if (row['accepted'] == 'DECLINED'){
                declined = declined + 1
            }
            if (row['accepted'] == 'ACCEPTED'){
                approved = approved + 1
            }
            if (row['accepted'] == 'NO DECISION'){
                pending = pending + 1
            }
            if (row['Country of Birth'] == 'South Africa'){
                sa = sa + 1
            } else {
                nonsa = nonsa + 1 
            }
            if (row['Application Type'] == 'First time applicant (matriculant)'){
                matric = matric + 1
            }
            if (row['Gender'] == 'Male'){
                male = male + 1
            } else {
                female = female + 1 
            }
            switch (row['Race']){
                case 'African':
                    african = african + 1
                case 'Coloured':
                    coloured = coloured +1 
                case 'Indian':
                    indian = indian + 1
                case 'White':
                    white = white + 1
                case'Foreign National':
                        foreign = foreign + 1
                case 'Other':
                    race_other = race_other + 1
            }
            
        } )

    }
       


ChartJS.register(ArcElement, Tooltip, Legend);


const dataGender = {
  labels: [`Male${male}`, `Female${female}`],
  datasets: [
    {
      label: '# of Votes',
      data: [male, female],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
        ],
    }
    var raceArray = []
    var raceLabels = []
    Object.keys(raceDataObject).map( key => {
        raceArray.push(raceDataObject[key].length)    
        raceLabels.push(`${key}${raceDataObject[key].length}`) 
    })
    const dataRace = {
            labels: raceLabels,
            datasets: [
              {
                label: '# of Votes',
                data: raceArray,
                backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 159, 64, 0.2)',
                      ],
                      borderColor: [
                      'rgba(255, 99, 132, 1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(255, 159, 64, 1)',
                      ],
                      borderWidth: 1,
                  },
                  ],
    }
  

    const RedirectPage = () => {
        switch(redirect){
            case "home":
                return <Home/>
            case "uni":
                return <Uni applications={application}/>
            case "income":
                return <GroupedByIncome applications={application}/>
            case "course":
                return <GroupedByCourse applications={application}/>
            case "level":
                return <GroupedStudyLevel applications={application}/>
            
        }
    }

    const ToolBar = ()=>{
        return (
            <Container>
                <button onClick={()=> setRedirect('uni')}>Number of students per institution</button>
                <button onClick={()=> setRedirect('course')}>Number of students per discipline</button>
                <button onClick={()=> setRedirect('income')}>Income bands</button>
                <button onClick={()=> setRedirect('level')}>Number of students per study level</button>
                <Stat/>
                
            </Container>	
        )
    }
    
    const Stat = () => {
    return ( 
        <div>          
                 
        <Container style={{marginTop: 70}}>
            <p>Amount Spent/budgeted (Rands): </p>
            <p>Number of Applications received: {all} </p> 
            <p>Number of Bursary Recipients: {approved} </p>
            <p>Number of Accepted applications: {approved} </p>
            <p>Number of declined applications: {declined} </p>
            <p>Number of foreign students: {nonsa} </p>
            <p>Number of non-Muslim students: {nonMuslim} </p>
            <p>Number of South African Students: {sa} </p>
            <p>Number of Matriculants: {matric} </p>
            <p>Number of SANZAF Sponsored:  </p>
            <p>Number of students at university, not sponsored:</p> 
            <p>Gender: Males vs Females:</p>              
            <div style={{  width: 300, height: 300}} > <Doughnut data={dataGender} />; </div>
            <p>Race:</p>
            <div style={{  width: 300, height: 300}} > <Doughnut data={dataRace} />; </div>
            <p>Post application survey stats:</p>
            <p>Number of students who applied for NSFAS:</p>
            <p>Spend per Student (Rands):</p>
            <p>% Increase in Spending:</p>
            <p>% increase in students:</p>
            <p>Average Student Pass rate:</p>
            <p>Graphs on results:</p>
            <p>Graduates – qualifying:</p>


        </Container>
    </div>)
    }

    const Home = ()=> {
		return (
				<div>
					{ ToolBar()}                   
				</div>  
		)
	}
    return (
			<div>
			<Container>
			<button onClick={()=> {   window.location.reload();}}> Back to Admin Page </button>
			</Container>
		    { RedirectPage()}	
			</div>
     
    );

    }

export default Statistics;