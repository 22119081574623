import React, { useState , useEffect} from 'react'
import { Link , useHistory} from 'react-router-dom'
import Layout from '../core/Layout'
import {Container} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import {jsPDF} from 'jspdf'
import axios from 'axios'
import { getStatus, applicationreport, getApplicant, 
        getApplication, downloadFile, fechData, downloadPledgeLetter } from '../util'
import { makeStyles } from '@material-ui/core/styles'
import { Alert, AlertTitle } from '@material-ui/lab'
import congrat from '../docs/CongratLetter2025.pdf'
import decline from '../docs/DeclineLetter2025.pdf'
import * as FileSaver from "file-saver";


const ApplicantDashboard = () => { 
    var fileURL
    const [status, setStatus] = useState();
    const [report, setReport] = useState();
    const [applicant, setApplicant] = useState()
    const [application, setApplication] = useState()
    const [data, setData] = useState()
    const [redirect,setRedirect] = useState('home')    
    const application_id =  JSON.parse(localStorage.getItem('application_id'))
    useEffect(  () =>{
        let mounted = true
            fechData().then(data => {		
            if (mounted){                
                setData(data)
            }
        })
        return ()=> {mounted = false}       
    },[]); 
    var history = useHistory()
    if (data != undefined ) {
      var first_name = data['First Name']
      var last_name = data['Last Name']
      var id_number = data['ID Number']
      var student_no = data['Student No']
      var university = data['University']
      var course = data['Course']
      var duration = data['Duration of Study']
    }
    const useStyles = makeStyles((theme) => ({
        root: {
          width: '100%',
          '& > * + *': {
            marginTop: theme.spacing(2),
          },
        },
    }));

    const DescriptionAlerts = () => {
      const classes = useStyles();
   
      return (
        
        <div className={classes.root}>
          { data != undefined && data['accept_decline'] == 1 && data['submited'] &&
          <Alert severity="success">
            <AlertTitle>Application outcome</AlertTitle>
              <p>Congratulations. You have been selected for SANZAF bursary 2025</p>           
             <div> <div  className="alert alert-info">  <a href={congrat} download="SANZAF_bursary_2025.pdf"> Download Congratulations letter </a>  </div>
             <div  className="alert alert-info">  <Link  onClick={()=>_downloadPledgeLetter()} >Download Pledge letter </Link>  </div>
             <div  className="alert alert-info">  <a href='' onClick={()=>history.push(`/uploaddocs/${application_id}`)}> Upload Supporting Documents </a>  </div>
              </div> 
          </Alert> }
          { data != undefined && data['accept_decline'] == 0 &&   data['submited']  &&
          <Alert severity="error">
            <AlertTitle>Application outcome</AlertTitle>
              <p>Thank you for your application for the SANZAF Bursary Programme. After a stringent
              assessment and review process, we regret to inform you that we are unable to offer you
              this bursary. Our decision is informed by the number of worthy and deserving students
              exceeding the budget set aside for the 2025 programme. Unfortunately, our funds are
              insufficient to meet the financial needs of all the applicants.
              The response to the SANZAF Bursary Fund was overwhelming with many applicants
              showing outstanding scholastic results, leadership, and community service activities. The
              SANZAF Bursary Programme application criterion was determined by the eligibility for
              Zakah.
              You are encouraged to apply for alternative funding such as the National Student Financial
              Aid Scheme of South Africa (NSFAS) email: info@nsfas.org.za and the bursary opportunities
              presented on the SA Bursaries website (www.sabursaries.com).
              We wish you all the best with your future endeavours and encourage you to continue to
              pursue your academic ambitions. May Allah SWT make it easy for you and your family,
              Insha’Allah.</p>
                <a href={decline} download="SANZAF_bursary_2025.pdf">Download decline letter</a>  
            </Alert>}
          {data != undefined && data['accept_decline'] == 2 && data['submited'] == '1' &&
           <Alert severity="success">
           <AlertTitle>SHUKRAAN FOR YOUR APPLICATION</AlertTitle>
            Your application for SANZAF bursary 2025 has been submitted successfully. The outcome of your application will be made  available here on your application profile, you will also be notified by email. You can access your profile by using your email and password. To log out click the icon on the top right of the page. Click log out.
            SANZAF wishes you all the best in your studies.
           
         </Alert>}
        </div>
      );
    }
    var img = new Image()
    var imgSignature = new Image()
    imgSignature.src = 'images/bg.png'
    img.src = 'images/signature.png'
    const date = `12 January 2025`
    const title = `Attention: ${university}`
    const body1 = `This letter serves to confirm that the South African National Zakah Fund (SANZAF) will \nprovide sponsorship for:` 
    const details =   `Student Name: ${first_name} ${last_name}\nID Number: ${id_number}\nCourse of Study: ${course}\nStudent No: ${student_no ? student_no : "N/A"}`                                            
    const body2 = "SANZAF will sponsor the above mentioned student with a part bursary for 2025 on \ncondition that the following documents reach SANZAF timeously."                       
    const docslist = "1. Official Fees Statement 2025\n2. Proof of University Registration 2025\n3. Proof of University Acceptance 2024\n4. Academic Transcript 2024\n"    
    const body3 = `SANZAF will not fund the student the event that the student has failed\nthe academic year. SANZAF will make payment directly to the University\nThe sudent may not withdrow any of the funds, even in credit, unless it\nis authorised by SANZAF in writing.` 
    const body4 = `We would like to appeal to the university to please forward the above-mentioned \nstudent’s Results and Fees Statements to SANZAF as they become available. \nPayment will be made to the university.`
   
    const sign1 =  "Yours Sincerely,"
   

   const sign2 = `Faeza Govind\nSANZAF Western Cape\nSEED Manager\nfaeza.govind@sanzaf.org.za\nTel: 021 638 5100`


   const  _downloadPledgeLetter=()=>{
    downloadPledgeLetter(data.applicant_id).then(response => {
      console.log(response)
      const plFile = new Blob([response.data])
			FileSaver.saveAs(new Blob([response.data]), `${data['First Name']} ${data['Last Name']}_pledge_letter.pdf`)

    })
    }

    
   const printDocument1 = (input)=> {
     const pdf = new jsPDF('p','mm', 'a4')
     var width = pdf.internal.pageSize.getWidth();
     var height = pdf.internal.pageSize.getHeight();
     pdf.addImage(img, 'PNG', 20, 0, 190, height  );
     pdf.addFont('ArialMS', 'Arial', 'normal');
     pdf.setFontSize(12);
     pdf.text(135, 40, date);
     pdf.setFontSize(13);
     pdf.setFont("normal")
     pdf.text(7, 50, title);
     pdf.setFont("normal")
     pdf.setFontSize(13);
     pdf.text(7, 60, body1);
     pdf.setFontSize(14);
     pdf.setFont("normal")
     pdf.text(10, 73, details);
     pdf.setFontSize(13);
     pdf.setFont("normal")
     pdf.text(7, 100, body2);
     pdf.setFontSize(13);
     pdf.setFont("normal")
     pdf.text(10, 115, docslist);
     pdf.setFontSize(14);
     pdf.setFont("normal")
     pdf.text(7, 140, body3);
     pdf.setFontSize(13);
     pdf.setFont("normal")
     pdf.text(7, 165, body4);     
     pdf.setFontSize(13);
     pdf.setFont("normal")
     pdf.text(17, 190, sign1);
     pdf.setFontSize(13);
     pdf.setFont("normal")
     pdf.text(17, 210, sign2);
     pdf.save(`${last_name}_SANZAF_Bursary_2024_Pleade_letter.pdf`);
    }
    const PledgeLetter = ()=>{
      return (
       <div className="pledgeLetter" id="pledgeLetter" name="pledgeLetter">
        sadsadasdfasdas sadadas sad sada as
      </div>
      )
    }
    return (
        <Layout >
            <Container maxWidth="md" style={{marginTop: 80}}>
              <DescriptionAlerts/>    
            </Container>
        </Layout>
    );
};

export default ApplicantDashboard;