import React, { useState } from "react";
import { useFormik } from 'formik'
import { Link , Redirect, useHistory } from "react-router-dom";
import  Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Layout from "../core/Layout";
import { signin, authenticate, isAuthenticated,getApplicantData } from "../util";
import * as Yup from 'yup';


const Signin = () => {
    const validationSchema = Yup.object({
        email: Yup.string().required('Enter your email'),
        password: Yup.string().required('Enter password')
    })
    const history = useHistory()
    const {handleSubmit, handleChange, handleBlur, setValues,  values, touched, errors } = useFormik({ 
        initialValues: {
            email: '',
            password: '',
            error: "",
            loading: false,
            redirectToReferrer: false
        },
        validationSchema
    });
    const { email, password, loading, error, redirectToReferrer } = values;

    const log  = isAuthenticated();



    const signinForm = () => (
    
             <form>
                 <div className="form-group">
                        <label className="text-muted" style={{marginTop: 10}}>Email</label> 
                        <input   id="email" name="email" className="form-control" onBlur={handleBlur} onChange={handleChange} type="email" value={email}  /> 
                        { touched.email && errors.email ? <div  className="alert alert-danger">  { errors.email} </div> : null} 
                </div>
                <div className="form-group">
                        <label className="text-muted">Password</label> 
                        <input   id="password" name="password" className="form-control" onBlur={handleBlur} onChange={handleChange} type="password" value={password}  /> 
                        { touched.password && errors.password ? <div  className="alert alert-danger">  { errors.password} </div> : null} 
                </div>       
            <div> 
                <input id="login" type="button" value="LOG IN" onClick={() =>{  if (proceed({email, password})) { clickSubmit() } }}  className="btn-success" style={{marginTop: 10, marginRight: 5}}/>
                
               {  <button onClick={() =>{ history.push('/register')}} className="btn-success" style={{marginTop: 10}}>
                Apply
                </button> }
                <Link to="/passwordchange" style={{marginTop: 40}}> Forgot Password</Link>
            </div>
        </form>

    );

     const clickSubmit = () => {
        setValues({ ...values, error: false, loading: true });
        signin({ email, password }).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, loading: false });
            } else {
                authenticate(data, () => {
                    setValues({
                        ...values,
                        redirectToReferrer: true
                    });
                });
            }
        });
    };

    const showError = () => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            {error}
        </div>
    );

    const showLoading = () =>
        loading && (
            <div className="alert alert-info">
                <h2>Loading...</h2>
            </div>
        );

    const redirectUser = () => {
       
        if (redirectToReferrer) {
            if (log && log.role === 1) {
                return <Redirect to="/admindashboard" />;
            } 
            if (log && log.role === 3 && log.application_status ) {
                 return <Redirect to="/applicantdashboard" />; 
            } 
            else {
                if (log.previous_year){
                    getApplicantData("2024").then( data => {			
                        if (data.success){
                            localStorage.setItem('applicant',JSON.stringify(data.success))
                            //return <Redirect to="/signup" /> 
                           
                        }
                        else {
                            localStorage.removeItem('applicant')
                            localStorage.setItem('applicant',JSON.stringify({}))
                        }
                        history.push('/signup')
                    }
                )
                }else {
                    history.push('/signup')
                }
               
            }
       
        }        
    }
    const  isEmpty = (obj) => {
        for (var key in obj) {
            if (obj[key] !== null && obj[key] != "")
                return false;
        }
        return true;
    }
    const  isError = (obj) => {
        for (var key in obj) {
            if (obj[key])
                return obj[key];
        }
        return false;
    }

    const proceed = (reg)=>{
        let err = { 
            email: '', re_email: '', password: ''
        };
        err = { 
             email: errors.email,  password: errors.password
        }; 
       
       if (isEmpty(reg))  {         
            setValues({ ...values, error: `Enter registration data`, success: false });       
            return false 
        } 
        if (isError(err)) {
            setValues({ ...values, error: isError(err) , success: false });          
            return  false       
        }
        setValues({...values, error: '' });     
        return true;
        //setValues({ ...values, error: false  });     
    }

    return (
        <Layout>
            <Container maxWidth="xs">
            {showLoading()}
           <div style={{ marginTop: 160 }}> {showError()} </div>
            {signinForm()}
            {redirectUser()} 
            </Container>
        </Layout>
    );
};

export default Signin;