import React from 'react';
import ReactDOM from 'react-dom';
import { Route } from 'react-router';
import Routes from './Routes'
import "./styles.css";


ReactDOM.render(

   <Routes/>,
  document.getElementById('root')
);
