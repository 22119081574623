import React, { useState , useEffect} from 'react';
import { Link, withRouter , useHistory} from "react-router-dom";
import {useForm, useStep } from 'react-hooks-helper'
import {Form, useFormik}  from 'formik'
import * as Yup from 'yup';
import  Container from '@material-ui/core/Container'
import FormLabel from '@material-ui/core/FormLabel'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Typography from '@material-ui/core/Typography'; 
import { makeStyles } from '@material-ui/core/styles';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetail from '@material-ui/core/AccordionDetails'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Layout from '../core/Layout';
import { sendLateApplicationRequest } from '../util';
import { TextareaAutosize, TextField } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import Checkbox from '@material-ui/core/Checkbox';
import { read } from 'xlsx';
import { Tune } from '@material-ui/icons';

const LateApplicationRequest = ({application}) =>{  
    const [display,setDisplay] = useState('comment')
    const [selectedField, selecField] = useState([]);
    const [selectedEmail, selecEmail] = useState([]);
    
    
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; 

    
    const upload = [ 'ID Document','Proof of Income','Testimonial Letter','Motivational Letter','Acceptance Letter',
                     'Result', 'Proof of Registration', 'Fees Statement','Consent Form' ]
    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email').required('Enter email'),
        cc: Yup.string().email('Invalid email for CC'),
        
    })
    var message_text  = ""
    
    const {handleSubmit, handleChange, handleBlur, setValues,  values, touched, errors } = useFormik({ 
        initialValues: {
            first_name: '',
            last_name: '',
            email:'',        
            cc:'',
            bcc:'',
            subject: 'SANZAF Bursary 2023 late application',  
            body: `Dear  \n \n\n \n \n \n Click on the link below or copy and paste it into a browser address bar \n \n url \n \n \n We thank you for your co-operation. \n Was Salaam`,
            text: '', 
            error: '',     
            loading: false,
       
        },
        validationSchema
    });
    const {  first_name,last_name,text, cc, email,subject, body, loading, error } = values;	

    useEffect(  () =>{
		let mounted = true
        //setValues({ ...values, body: `Dear  \n \n\n \n \n \n Click on the link below or copy and paste it into a browser address bar \n \n url \n \n \n We thank you for your co-operation. \n Was Salaam`  });  
		return ()=> {mounted = false}       
    },[]); 
    
    //setValues({ ...values, body: '' });  
    
    const  isError = obj => {
        for (var key in obj) {
            if (obj[key])
                return obj[key];
        }
        return false;
    }

  


    const LateApplicationForm = ()=>{

        const handleFirstName = (event) => {
            setValues({...values, first_name: event.target.value, body: `Dear ${first_name} ${last_name}  \n \n\n \n \n \n Click on the link below or copy and paste it into a browser address bar \n \n url \n \n \n We thank you for your co-operation. \n Was Salaam`})
            
            
        }
        const handleLastName = (event) => {
            setValues({...values, last_name: event.target.value, body: `Dear ${first_name} ${last_name}  \n \n\n \n \n \n Click on the link below or copy and paste it into a browser address bar \n \n url \n \n \n We thank you for your co-operation. \n Was Salaam`})
            
        }
        const handleBody = (event) => {
            setValues({...values, body: event.target.value})         
        }
        return (
            <Container>               

               

                    <div className="form-group">
                        <label className="form-label">Email</label>                                            
                         <input className="form-control" id="email" name="email"  onBlur={handleBlur} onChange={handleChange} type="text" value={email}  /> 
                        { touched.email && errors.email ? <div  className="alert alert-danger"> { errors.email} </div> : null}                        
                    </div>
                    <div className="form-group">
                        <label className="form-label">CC</label>                                            
                         <input className="form-control" id="cc" name="cc"  onBlur={handleBlur} onChange={handleChange} type="text" value={cc}  /> 
                        { touched.cc && errors.cc ? <div  className="alert alert-danger"> { errors.cc} </div> : null}                        
                    </div>
                    <div className="form-group">
                        <label className="form-label">Subject</label>                                            
                         <input className="form-control" id="subject" name="subject"  onBlur={handleBlur} onChange={handleChange} type="text" value={subject}  /> 
                        { touched.subject && errors.subject ? <div  className="alert alert-danger"> { errors.subject} </div> : null}                        
                    </div>
                    <div className="form-group">
                        <label className="form-label">Body</label>
                      
                        <textarea id="email" name="body" rows="15" onBlur={handleBlur} onChange={handleChange} type="text" className="form-control" value={body}/>
                        {touched.body && errors.body ? <div  className="alert alert-danger"> { errors.body } </div> : null}                 
                    </div>
                    <div> 
                        <input id="mail" type="button" value="Send" onClick={() => sendApplicationRequest() } style={{marginleft: 10}} className="btn-success form-control btn-next" style={{marginTop: 10}}/>                            
                    
                    
                    
                    </div>
            </Container>
        )
    }

    const showNotes = () =>{
        return (<div> 
       { application.review.map(item => (         
             <div> { (item['Comment Date']).substring(0,10)}   { item['Comment']}</div>   
        ))} 
    </div> )
    }
     
    const showLoading = () =>
        loading && (
            <div className="alert alert-info">
                <h2>sending...</h2>
            </div>
        );


    const sendApplicationRequest = ()=> {
        window.scrollTo(0, 0);
        setValues({ ...values, error: false, loading: true });   
        sendLateApplicationRequest( {email,cc,subject,body} ).then(data => {
                    if (data.error) {
                        setValues({ ...values, error: false, loading: false });
                        alert(data.error)
                    } else {
                    setValues({ ...values, error: false, loading: false });
                    alert(`Late application request sent`)
                    }
                })
        
        }
    return(
        <div>
            <button onClick={()=> {   window.location.reload();}}> Back to Admin Page </button>
            <Container maxWidth="sm">
            {showLoading()}
            <form>     
                {LateApplicationForm()}                 
            </form>
            </Container>
        </div>
    )
    
}
export default LateApplicationRequest;