import React, { useState , useEffect} from 'react';
import { Link, withRouter , useHistory} from "react-router-dom";

import {useForm, useStep } from 'react-hooks-helper'
import {Form, useFormik}  from 'formik'
import * as Yup from 'yup';
import  Container from '@material-ui/core/Container'
import FormLabel from '@material-ui/core/FormLabel'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Typography from '@material-ui/core/Typography'; 
import { makeStyles } from '@material-ui/core/styles';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetail from '@material-ui/core/AccordionDetails'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Layout from '../core/Layout';
import { sendComment, sendDocumentsRequest } from '../util';
import { TextareaAutosize, TextField } from '@material-ui/core';

const SendAcceptEmails = () =>{  
    const [display,setDisplay] = useState('comment')
    const validationSchema = Yup.object({
        email: Yup.string().required('Enter your Eamil'),
        comment: Yup.string().required('enter comment')
    })
    const text = `We hope that you have had a restful holiday period and that you are ready to take on the academic year ahead, in shaa Allah. 
    Kindly be reminded of the following 
    If you have not submitted the following documents, please do so before the 15 March 2021: 
    1. Matric Results 2020 
    2. University Academic results 2021 
    3. Proof of registration 2022 
    4. Official Statement of fees 2022 with banking details 
    5. Acceptance from university 2022 
    If you have already submitted these documents for your 2021 bursary application, please do not submit them it again. 
    In the event that you did not submit the following with your application form when you completed the online application, please do so immediately: 
    1.  Copy of ID document 
    2.  Proof of income 
    3.  Testimonial 
    4.  Motivation letter 
    In the event that you did submit please ignore email.   
    Kindly note that it is your responsibility to ensure that all required documents are submitted timeously. We do however acknowledge that these documents may be delayed due to issues at universities. If this is the case please let us know via email.\n
    We thank you for your co-operation.
    Was Salaam`
    const {handleSubmit, handleChange, handleBlur, setValues,  values, touched, errors } = useFormik({ 
        initialValues: {
            comment:'',
            email: '' ,
            cc:'',
            subject: 'Request for outstanding documnets 2022',
            body: text,
            loading: false,
            redirectToReferrer: false
        },
        validationSchema
    });
    const { comment, cc, email,re_email ,subject, body, loading, error, redirectToReferrer } = values;	
    const  notEmpty = (obj) => {
        
        for (var key in obj) {
            if (obj[key] == ""){
                alert(`Select File for ${key}`)
                return false;
            }
        }
        return true;
    }
    const  isError = obj => {
        for (var key in obj) {
            if (obj[key])
                return obj[key];
        }
        return false;
    }
    

    const noError = (data, err )=>{

     /*  if (notEmpty(data))  {         
            setValues({ ...values, error: `Enter Regisration Data`, success: false });
            return false 
        } */
        if (isError(err)) {
            setValues({ ...values, error: isError(err) , success: false });
            return  false       
        }
        setValues({...values, error: '' });
        return true;
        //setValues({ ...values, error: false  });

    }
    const saveComment = ()=> {
        //setValues({ ...values, error: false, loading: true });
        sendComment( comment ).then(data => {
            if (data.error) {
                alert(data.error)
            } else {
             alert('Comment Save')
            }
        });
    }
    
    const sendEmail = ()=> {
        //setValues({ ...values, error: false, loading: true });
        sendDocumentsRequest( email,cc,subject,body ).then(data => {
            if (data.error) {
                alert(data.error)
            } else {
             alert('Email Sent')
            }
        });
    }
   
    const Comment = ()=>{
        return (
            <Container >
                <div style={{ color: 'red', fontSize: 20 }} >
                     
                </div>
                <form key='commentform'>
                    <div className="form-group"   >
                        <label className="form-label">Comment</label> 
                        <textarea key="comment" id="comment" name="comment" className="form-control" onBlur={handleBlur} onChange={handleChange} type="text" value={comment}  /> 
                        { touched.comment && errors.comment ? <div  className="alert alert-danger">  { errors.comment} </div> : null} 
                    </div>
                    <div> 
                        <input id="re" type="button" value="Save" onClick={ () => saveComment() }  style={{marginleft: 10}} className="btn-success form-control btn-next" style={{marginTop: 10}}/>
                     
                    </div>
                </form>
             
             </Container  >
        )
    }
    const EmailForm = ()=>{
        return (
            <Container>
                    <div className="form-group">
                        <label className="form-label">to</label>
                            <input className="form-control" id="email" name="email"  onBlur={handleBlur} onChange={handleChange} type="email" value={email}/>  
                          { touched.email && errors.email ? <div  className="alert alert-danger"> { errors.email} </div> : null}               
                    
                    </div>
                    <div className="form-group">
                        <label className="form-label">cc</label>                                            
                         <input className="form-control" id="cc" name="cc"  onBlur={handleBlur} onChange={handleChange} type="text" value={cc}  /> 
                        { touched.cc && errors.cc ? <div  className="alert alert-danger"> { errors.cc} </div> : null}                        
                    </div>
                    <div className="form-group">
                        <label className="form-label">subject</label>                                            
                         <input className="form-control" id="subject" name="subject"  onBlur={handleBlur} onChange={handleChange} type="text" value={subject}  /> 
                        { touched.subject && errors.subject ? <div  className="alert alert-danger"> { errors.subject} </div> : null}                        
                    </div>
                    <div className="form-group">
                        <label className="form-label">body</label>
                      
                        <textarea id="body" name="body" rows="15" onBlur={handleBlur} onChange={handleChange} type="text" className="form-control" value={body}/>
                        {touched.body && errors.body ? <div  className="alert alert-danger"> { errors.body } </div> : null}                 
                    </div>
                    <div> 
                        <input id="mail" type="button" value="Send" onClick={() => {} } style={{marginleft: 10}} className="btn-success form-control btn-next" style={{marginTop: 10}}/>
                            
                    
                    
                    
                    </div>
            </Container>
        )
    }

    const showNotes = () =>{
        return (<div> 
      
    </div> )
    }
    const DisplayForm = () =>{
        switch(display){
            case 'comment':
                return Comment()
            case 'emailform':
                return EmailForm()
        }
    }
    return(
        <div>
            
            <form>
                {EmailForm()}   
               
                      
            </form>
        </div>
    )
    
}
export default SendAcceptEmails;