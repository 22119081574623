import React, { useState , useEffect, useRef, } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import {Form, useFormik}  from 'formik'
import * as Yup from 'yup';
import { getStatus, applicationreport, downloadFileAdmin, sendpledgeletter, sendAcceptDecline, sendComment } from '../util';
import Review from './Review'
import AcceptDecline from './AcceptDecline'
import Payment from './Payment'
import Result from './Result'

 
import Container from '@material-ui/core/Container'
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetail from '@material-ui/core/AccordionDetails'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



import {jsPDF} from 'jspdf'

import axios from 'axios'


const GroupedByCourse = ({applications}) => {
	
	const [redirect,setRedirect] = useState('home')
	const history = useHistory()
	var fileURL
	const validationSchema = Yup.object({
        email: Yup.string().required('Enter your Eamil'),
        comment: Yup.string().required('Password is Required')
    })

	const {handleChange, handleBlur, setValues,  values, touched, errors } = useFormik({ 
        initialValues: {
            comment:'',
            email: '',
            cc:'',
            subject: '',
            body:'',
            loading: false,
            redirectToReferrer: false
        },
        validationSchema
    });
	const { comment, cc, subject, body, loading, error, redirectToReferrer } = values
	var groupBy = (array, predicate) => {
		var grouped = {}
		for(var i = 0; i < array.length; i++) {
			var groupKey = predicate(array[i]);
			if (typeof(grouped[groupKey]) === "undefined")
				grouped[groupKey] = [];
			grouped[groupKey].push(array[i]);
		}
	
		return grouped;
	}
	var groupData = groupBy(applications,  (obj) => {
		return obj["Course"];
	});		



	var upload3  = { 
        "ID Document": 'id_document' ,"Acceptance Letter": 'acceptance_letter' , "Proof of Income": 'affidavit' , 
        "Testimonial Letter": 'recommendation' ,"Motivational Letter": 'motivation_letter'   
    }
	const ReviewPage = () => {		
		  return (
			  <div> 
				  <Container maxWidth="lg">	
                  <h2>Number of students per discipline</h2>	
				  {  Object.keys(groupData).map( key =>
					  		<Report lable={key}   data= {groupData[key]}/>
					  )}
				  </Container>	
				          
			  </div>
		  ) 
	}
	const Report = ({lable, data}) => {
		const header = `${lable} (${data.length})`
		return ( 
				<div>                                     
				
                    
					<Accordion>
						<AccordionSummary on expandIcon={<ExpandMoreIcon />}> {header}
						</AccordionSummary>
						<AccordionDetail>          
						<div>
		              		<table style = {{width: 800}}  border='1' frame='void' rules='rows' >
							  <tr><tr>
							        <th>no</th>
									<th>First Name</th>
									<th>Surname</th>
                                    <th>University</th>
									<th>Course</th>
									<th>Study Level</th>

								</tr>
		                    {  data.map( item => 
							 
								<tr>
							        <td>{ parseInt(data.indexOf(item) +1 )}</td>
									<td>{item["First Name"]}</td>
									<td>{item["Last Name"]}</td>
                                    <td>{item["University"]}</td>
									<td>{item["Course"]}</td>
									<td>{item["Study Level"]}</td>
								</tr> )}
								</tr>
							
								</table>
							</div>	
						</AccordionDetail>
					</Accordion>
				</div>
		   )     
    } 
	return (
			<Container>
			   <ReviewPage/>
			  
		    </Container>	
		)


}
export default GroupedByCourse;

