import React, { useState ,useRef, useEffect } from 'react';
import { Link, withRouter , useHistory} from "react-router-dom";
import {useForm, useStep } from 'react-hooks-helper'
import  Container from '@material-ui/core/Container'
import Layout from '../core/Layout';
const HowToApply = () => {
    window.scrollTo(0, 0);
    return (
        <div>
            <Layout>
                <Container >
                    <div>
                    <img src="images/home.png" class="img-fluid" alt=""  width="8000" height="8000"/> 
                    </div>   
                    <Container maxWidth="xs">
                <div  className="alert alert-info"> New Applicant: Click Apply Now. Returning Applicant: Click Login. Enter your Email and Password and proceed with application accordingly.</div>
                </Container>         
                </Container>
                <br></br>
                <br></br>
                <Container maxWidth="lg">
                    <div>
                    <img src="images/tc.png" class="img-fluid" alt="" width="4000" height="4000"/> 
                    </div>   
                    <Container maxWidth="xs">
                <div  className="alert alert-info"> Scroll down , check the Terms and Conditions and Click Next button</div>
                </Container>         
                </Container>
                <br></br>
                <br></br>
  
                <Container maxWidth="lg">
                    <div>
                    <img src="images/registration.png" class="img-fluid" alt="" width="4000" height="4000"/> 
                    </div>   
                    <Container maxWidth="xs">
                <div  className="alert alert-info"> Create an account by entering your First name , Last name, Email and select Password. Click Register button</div>
                </Container>         
                </Container>
                <br></br>
                <br></br>
                <Container maxWidth="lg">
                    <div>
                    <img src="images/prompt.png" class="img-fluid" alt="" width="4000" height="4000"/> 
                    </div>   
                    <Container maxWidth="xs">
                <div  className="alert alert-info"> After successful registration you will be prompted to login. Click OK.  </div>
                </Container>         
                </Container>
                <br></br>
                <br></br>
                <Container maxWidth="lg">
                    <div>
                    <img src="images/login.png" class="img-fluid" alt="" width="4000" height="4000"/> 
                    </div>   
                    <Container maxWidth="xs">
                <div  className="alert alert-info"> To log in enter your email and password. You can also click on Login at top right of the home page to log into your account. </div>
                </Container>         
                </Container>
                <br></br>
                <br></br>
                <Container maxWidth="lg">
                    <div>
                    <img src="images/basicinfo.png" class="img-fluid" alt="" width="4000" height="4000"/> 
                    </div>   
                    <Container maxWidth="xs">
                <div  className="alert alert-info"> Basic Information section. Enter and Save   </div>
                </Container>          
                </Container>
                <br></br>
                <br></br>
                <Container maxWidth="lg">
                    <div>
                    <img src="images/sociinfo.png" class="img-fluid" alt="" /> 
                    </div>   
                    <Container maxWidth="xs">
                <div  className="alert alert-info"> Social Information section. Enter and Save  </div>
                </Container>         
                </Container>
                <br></br>
                <br></br>
                <Container maxWidth="lg">
                    <div>
                    <img src="images/university.png" class="img-fluid" alt="" /> 
                    </div>   
                    <Container maxWidth="xs">
                <div  className="alert alert-info"> If your University is not found on the Universities drop-down list, select Other and enter your university in Please Specify field. The same applies to your Course selection  </div>
                </Container>         
                </Container>
                <br></br>
                <br></br>   
                <Container maxWidth="lg">
                    <div>
                    <img src="images/file.png" class="img-fluid" alt="" /> 
                    </div>   
                    <Container maxWidth="xs">
                <div  className="alert alert-info"> Select file and click Upload File for the required documents and then click Save and Continue. Each file must not exceed 2 MB  either in PDF or IMAGE format  </div>
                </Container>         
                </Container>
                <br></br>
                <br></br>    
                <Container maxWidth="lg">
                    <div>
                    <img src="images/income.png" class="img-fluid" alt="" /> 
                    </div>   
                    <Container maxWidth="xs">
                <div  className="alert alert-info"> Enter amounts for income. If not applicable enter "0"  </div>
                </Container>   
                </Container>
                <br></br>
                <br></br>   
                <Container maxWidth="lg">
                    <div>
                    <img src="images/expense.png" class="img-fluid" alt="" /> 
                    </div>   
                    <Container maxWidth="xs">
                <div  className="alert alert-info"> Enter amounts for expenses. If not applicable enter "0"</div>
                </Container>         
                </Container>
                <br></br>
                <br></br>   
                <Container maxWidth="lg">
                    <div>
                    <img src="images/survey.png" class="img-fluid" alt="" /> 
                    </div>   
                    <Container maxWidth="xs">
                <div  className="alert alert-info"> Complete the Survey section. Click Save and Continue  </div>
                </Container>     
                </Container>
                <br></br>
                <br></br>   
                <Container maxWidth="lg">
                    <div>
                    <img src="images/submit.png" class="img-fluid" alt="" /> 
                    </div>   
                    <Container maxWidth="xs">
                <div  className="alert alert-info"> Check and Verify your information. You can rectify your information using the edit icon to go back to the relevent form. Check the Pledge by Applicant confirmation. Click Submit button</div>
                </Container>   
                </Container>
                <br></br>
                <br></br>   
                <Container maxWidth="lg">
                    <div>
                    <img src="images/applicant_page.png" class="img-fluid" alt="" /> 
                    </div>   
                    <Container maxWidth="xs">
                <div  className="alert alert-info">  You will receive a confirmation email. The outcome of your application will be made available on your application profile. You can access your profile by using your email and password </div>
                </Container>         
                </Container>

            </Layout>
                
        </div>
    )
}

export default HowToApply