import React, { Fragment, useState, useEffect } from "react";
import { Form, useFormik } from "formik";
import { Link, useHistory,useLocation, withRouter } from "react-router-dom";
import { signout, isAuthenticated  } from "../util";

import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Avatar from "@material-ui/core/Avatar";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';


import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';
import { AlternateEmail } from "@material-ui/icons";
Number.prototype.format = function(n, x) {
  var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
  return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
};
const sumReceiptItemsAmount = (receiptItems) => {
  let total = 0
  receiptItems.map(item => {
      total = total + parseFloat(item['amount'])
  })
  return total
}
const sumReceiptsAmount = (receipts) => {

  let total = 0
  receipts.map(receipt => {
      total = total + sumReceiptItemsAmount(receipt['receipt_items'])
      
  })
  return total
}
const sumBatchesAmount = (batches) => {
  let total = 0
  batches.map(batch => {
      total = total + sumReceiptsAmount(batch['receipts'])
      
  })
  return total
}
const sumTotalBank = (receipts) => {
  let total = 0
  receipts.map(receipt => {
      let bank = parseFloat(receipt['total_bank']) || 0
      total = total + bank
  })
return total
}
const sumTotalCash = (receipts) => {
  let total = 0
  let cash = 0
  receipts.map(receipt => {
      cash =  parseFloat(sumReceiptItemsAmount(receipt['receipt_items']))
      let bank = parseFloat(receipt['total_bank']) || 0
      total = total + cash - bank
  })
  return total
}

const sumBatchesBankAmount = (batches) => {
  let total = 0
  batches.map(batch => {
      total = total + sumTotalBank(batch['receipts'])
      
  })
  return total
}
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export default function PrimarySearchAppBar() {
  var cash_amount
  const [cash, setCash] = useState(0)
  const [bank, setBank] = useState(0)
  
  var batchesLength = 0
  var batches  =  localStorage.getItem('batches')
  if (batches != undefined){
    batches = JSON.parse(batches)
  }else {
    batches = []
  }


  var first_char, second_char
  if (isAuthenticated()) {
    second_char  = (isAuthenticated().last_name)[0].toUpperCase()
    first_char= (isAuthenticated().first_name)[0].toUpperCase()   
  } 

  const history = useHistory()
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorBatchesMenu, setAnchorBatchesMenu] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const loc = useLocation()
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    setFieldValue,
    values,
    touched,
    errors,
      } = useFormik({
          initialValues: {
              staff_id:'',
              batchesData: [],
              loading: false,
              success: false,
              error: "",
          },
  
          validateOnChange: true,
      });

  const {
      batchesData,
      staff_id,
      loading,
      error,
      success,
  } = values;


  
  const isMenuOpen = Boolean(anchorEl);
  const isBatchesMenuOpen = Boolean(anchorBatchesMenu);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  var batchesLength
 



  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  
  const handleBatchesMenuOpen = (event) => {
    setAnchorBatchesMenu(event.currentTarget);
  };


  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setAnchorBatchesMenu(null)
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const logout = ()=> {
    signout(() => {
      history.push("/signin");
  })
  }

  const login = ()=> {  
    history.push("/signin");
  }
  const goToProfile = ()=>{
    //history.push('/applicantdashboard')
  }
  const signup = ()=> {  
    history.push("/signup");
}



  const SideMenu = () => {
    const drawerWidth = 240;
    const history = useHistory()

    const useStyles = makeStyles({
        drawerPaper: {
         marginTop: "63px"
        }
      });
    const classes = useStyles();
    const adminmenuList = ['Applicants', 'Payment','Results','Stats']
    const menuList = [ 'Donors','Batches']
    const pushTo = (index) => {     
      switch(menuList[index]){
        case "Donors":
          if (loc.pathname == '/donors') {
            window.location.reload();
          } else  history.push('/donors')
          break
        case "Batches":
          if (loc.pathname == '/batches'){
            window.location.reload();
          }  else history.push('/batches')
          break
        
      }
    }
    const pushToAdmin = (index) => {     
      switch(adminmenuList[index]){
        case "Staff":     
          if (loc.pathname == '/staffs') {
            window.location.reload();
          } else  history.push('/staffs')
          break
        case "Donors":        
          if (loc.pathname == '/donors') {
            window.location.reload();
          } else  history.push('/donors')
          break
        case "Batches":
          if (loc.pathname == '/batches') {
            window.location.reload();
          } else  history.push('/batches')
          break
        case "Bank Report":        
        if (loc.pathname == '/reports') {
          window.location.reload();
        } else  history.push('/reports')
          break           
      }
    }
return (
  <Box    style={{ position:"absolute"}} >
 
      <Drawer classes={{  paper: classes.drawerPaper }}
    sx={{
    width: drawerWidth, 
    flexShrink: 0, '& .MuiDrawer-paper': {
      width: drawerWidth,
      boxSizing: 'border-box',
    },
    }}
 
    position="fixed"
    variant="permanent"
    anchor="left"
  >
    
    <Divider />
    { isAuthenticated().role == 1 ? <List >
              {adminmenuList.map((text, index) => (
                <ListItem button key={text} onClick={ () => pushToAdmin(index)}>
                <ListItemIcon>
                  {index  === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
                </ListItem>
              ))}
          </List> :
          
          <List >
              {menuList.map((text, index) => (
                <ListItem button key={text} onClick={ () => pushTo(index)}>
                <ListItemIcon>
                  {index  === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
                </ListItem>
              ))}
          </List>
          
          }



    <Divider />

  </Drawer>
   
  </Box>
);
}
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
     { <MenuItem onClick={goToProfile}>Profile</MenuItem> }
      <MenuItem onClick={logout}>Logout</MenuItem>
    </Menu>
  );
  const BatchesMenu = (
    <Menu 
      anchorEl={anchorBatchesMenu}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isBatchesMenuOpen}
      onClose={handleMenuClose}
    >
    
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
 
   
      <MenuItem onClick={handleProfileMenuOpen}>
      <Button
            aria-controls="fade-menu"
            aria-haspopup="true"
            //onClick={handleClick}
        >
             <Avatar mx="auto" className={classes.purple}>{first_char}{second_char}</Avatar>             
             </Button>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      {/*{ isAuthenticated() &&  isAuthenticated().role == 1 &&  <SideMenu/> } */}
			
		 
      <AppBar position="static" className="bg-primary">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton>
            <Typography className={classes.title} variant="h6" noWrap>
          
            SANZAF Zakah Bursary
          </Typography>
          
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
         
           
          
         
          {!isAuthenticated() &&  <Button color="inherit" onClick={login}>Login</Button> }
          {!isAuthenticated() &&  <Button color="inherit" onClick={()=> history.push('/register')} >Apply</Button> } 
          { isAuthenticated() && 
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
         
           
         
            <Button
              aria-controls="fade-menu"
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
            >
              <Avatar mx="auto" className={classes.purple}>{first_char}{second_char}</Avatar>
                 
              </Button>
            </Box> }
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
     { isAuthenticated() && <div> {renderMobileMenu} </div> }
     { isAuthenticated() && <div> {renderMenu} </div> }
     {BatchesMenu}
    </div>
  );
}