import React, { useState } from "react";
import {  Redirect } from "react-router-dom";
import  Container from '@material-ui/core/Container'
import Layout from "../core/Layout";
import { passwordhandle , isAuthenticated } from "../util";

const PasswordChange = () => {
    const [values, setValues] = useState({
        email: '',
        error: "",
        success: '',
        loading: false,
        redirectToReferrer: false
    });

    const { email, loading, error, success, redirectToReferrer } = values;
    const { user } = isAuthenticated();

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
    };

    const clickSubmit = event => {
        event.preventDefault();
        setValues({ ...values, error: false, loading: true });
        passwordhandle({ email }).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, loading: false });
            } else { 
                setValues({ ...values, success: data.success, loading: false });
               
            }
        });
    };

    const passwordChangeForm = () => (
        
        <form>
            <div className="form-group" style={{marginTop: 90}}>
            <label className="text-muted" style={{marginTop: 10}}>Email</label> 
                <input
                    onChange={handleChange("email")}
                    type="email"
                    className="form-control"
                    value={email}
                />
            </div>        
            <button onClick={clickSubmit} className="btn btn-primary"  style={{marginTop: 10}}>
                Submit
            </button>
        </form>
    );

    const showError = () => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            {error}
        </div>
    );

    const showSuccess = () => (
        <div
            className="alert alert-info"
            style={{ display: success ? "" : "none" }}
        >
            {success}
        </div>
    );


    const showLoading = () =>
        loading && (
            <div className="alert alert-info">
                <h2>Loading...</h2>
            </div>
        );

    const redirectUser = () => {
        if (redirectToReferrer) {
            if (user && user.role === 4) {
                return <Redirect to="/admin/dashboard" />;
            } else {
                return <Redirect to="/" />;
            }
        }
    };


    return (
        <Layout>
            <Container maxWidth="sm">
            {showLoading()}
            {showError()}
            {showSuccess()}
            {passwordChangeForm()}
            {redirectUser()}
            </Container>
        </Layout>
    );
};

export default PasswordChange;
