import React, {Component} from 'react'
import {Route, Redirect } from 'react-router-dom'
import {isAuthenticated} from './index'


const AdminRoute = ({component: Component, ...rest })=> (
    <Route {...rest} render={ props => (isAuthenticated() && isAuthenticated().role === 1 ) ? ( <Component {...props}/>) : 
    (<Redirect to= {{pathname:'/signin', satae: {from: props.location }}}/>)}/>
)
export default AdminRoute
