import React, { useState , useEffect} from 'react';
import { Link, withRouter , useHistory} from "react-router-dom";

import {useForm, useStep } from 'react-hooks-helper'
import {Form, useFormik}  from 'formik'
import * as Yup from 'yup';
import  Container from '@material-ui/core/Container'
import FormLabel from '@material-ui/core/FormLabel'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Typography from '@material-ui/core/Typography'; 
import { makeStyles } from '@material-ui/core/styles';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox';
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetail from '@material-ui/core/AccordionDetails'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Layout from '../core/Layout';
import { sendComment, addemail, addPayment, deletePayment } from '../util';
import { TextareaAutosize, TextField } from '@material-ui/core';
import * as dump  from '../dump'


const Payment = ({applicant}) =>{
    console.log(applicant)
    const [display,setDisplay] = useState('report')
    const validationSchema = Yup.object({
        bank: Yup.string().required('Bank is required'),
        //account_no: Yup.string().required("Enter cell phone").matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,'invalid phone number'),
        amount_approved: Yup.number().required('Amount approved is required'),
    })

 
    const {handleSubmit, handleChange, handleBlur, setValues, setFieldValue, values, touched, errors } = useFormik({ 
        initialValues: {
            payment_id: '1',
            application_id: applicant.application_id,
            reference: applicant['Student Number'] ? applicant['Student Number'] : applicant['ID Number'],
            in_favour_of: applicant['University'],
            bankig_details: '',
            bank: '',
            account_no:'',
            branch_code:'',
            in_respect_of: '',            
            account:'Trust',
            amount_approved: '',
            eft_chq: 'EFT',
            approved1:'AGS',
            approved2:'MSB',
            approved3:'',
           
            percentage:'',
            comment:'',
            education_debt:'',
            loading: false,
            redirectToReferrer: false
        },
        validationSchema
    });
  
    const history = useHistory()
    const [updated,setUpdated] = useState(false)
    const {  payment_id, application_id, reference, in_favour_of,bankig_details, bank, account_no, branch_code,in_respect_of,
        account, amount_approved, eft_chq, approved1,approved2, approved3, percentage, education_debt, comment,  } = values;
    const payment = {payment_id, application_id, reference, in_favour_of, bank, account_no, branch_code,in_respect_of,
        account, amount_approved, eft_chq, approved1,approved2 } 	
    var showBank = true	
 
        const  notData = (data) => {        
            for (var key in data) {
                if (data[key] == ""){
                    alert(`Enter ${key}`)
                    return true                   
                }
            }
            return false
        }
        const  isError = (data, err) => {
            for (var key in err) {
                if (err[key])
                    return err[key];
            }
            return false;
        }
        
    
        const noError = (data, err )=>{
            if (notData(data)){                
                return false
            }
            let error = isError(data,err)
            if (error) {
                alert(error)
                //setValues({ ...values, error: isError(err) , success: false });
                return  false       
            }
            setValues({ ...values, error: ''});
            //setValues({...values, error: false} );
            return true;
            //setValues({ ...values, error: false  });
        }
      
    const savePayment = (payment)=> {
        //setValues({ ...values, error: false, loading: true });
        addPayment(payment).then(data => {
            if (data.error) {
                alert(data.error)
            } else {
             window.scrollTo(0, 0)
             setUpdated(true)
             alert("Payment record added/updated successfully ")
             
             
            }
        });
    }
    const deleteRecord = (payment)=> { 
        console.log(payment)     
        //setValues({ ...values, error: false, loading: true });
        deletePayment(payment).then(data => {
            if (data.error) {
                alert(data.error)
            } else {
             window.scrollTo(0, 0)
             alert("Payment record deleted")
            // setUpdated(true)
             
             
            }
        });
    }
    const PaymentReport = () => {
        const ReviewPage = () => {
            return (
                <div> 
                    <Container maxWidth="lg">
                      { applicant.payment.map( index =>
                               <Report lable={"Payment Details"}  data= {index} />
                      )}                   
                    </Container>	
                    <div style={{marginTop: 40}} >
                        <label className="form-label">{  }
                        </label>  
                    </div>              
                </div>
            ) 
        }
        const Report = ({lable, data}) => {

            return ( 
                    <div>                                     
                        <Accordion>
                            <AccordionSummary on expandIcon={<ExpandMoreIcon />}> {lable}
                            </AccordionSummary>
                            <AccordionDetail>          
                                <div>
                                    <table >
                                        <tr>
                                            { Object.keys(data).map(key =>
                                                <div> 
                                                    <th> {key} </th> 	
                                                    <td> {data[key]}</td>
                                                </div>)}                        
                                            </tr>        
                                    </table>
                                  <IconButton onClick={ ()=>{ setDisplay('form'); 
                                                             setValues({...values, 
                                                                        payment_id: data['payment_id'],                                                                        
                                                                        reference: data['Reference'],
                                                                        in_favour_of: data['In Favour of'],
                                                                        bank: data['Bank'],
                                                                        account_no: data['Account no'],
                                                                        account: data['Account'],
                                                                        branch_code: data['Branch Code'],
                                                                        in_respect_of: data['In respect of Education/Debt'],
                                                                        amount_approved: data['Amount Approved'],
                                                                        eft_chq: data['EFT or Cheque'],
                                                                        approved1: data['Approved1'],
                                                                        approved2: data['Approved2'],
                                                                     
                                                                        });  }} color="primary" component="span"><EditIcon /></IconButton> 
                                <IconButton onClick={ ()=>{ 
                                                        setValues({...values});
                                                        deleteRecord({ payment_id: data['payment_id']}); data = {};
                                                        
                                                        }} color="primary" component="span"><DeleteIcon /></IconButton> 
                                </div>
                            </AccordionDetail>
                        </Accordion>
                    </div>
               )     
        }                                                                       
       
        return (
                <Container>
                      <div >
                           <ReviewPage/>          
                        </div>
                    <Button onClick={ ()=> setDisplay('form') }  style={{marginleft: 10}} variant="contained"  color="primary" style={{marginTop: 10}}>
                             Add Payment Record
                    </Button>  
                </Container>
        )
    }
    const showUpdated = () => (
        <div   className="alert alert-info">
            Payment record added/updated successfully <Link onClick={ ()=> window.location.reload() }>Back to applicant page</Link>
        </div>
    );
    const handlePaymentDetailes = (event) =>{  
            showBank = false   
            setValues({
                ...values, 
                bank: dump.paymentProfile[event.target.value]['payment_info']['bank'],
                account_no: dump.paymentProfile[event.target.value]['payment_info']['account_no'],
                branch_code: dump.paymentProfile[event.target.value]['payment_info']['branch_no'],
               
              });      
    }
  
    const PaymentForm = ()=>{
        
        const err = { amount_approved: errors.amount_approved }
        return (
            <Container maxWidth="xs">
                <form>                                  
                   <h2> <div> <label className="form-label" >{applicant['First Name']} {applicant['Last Name']}</label></div> </h2>
                    <div className="form-group">
                        <label className="form-label">Reference</label> 
                        <input  id="reference" name="reference" className="form-control" onBlur={handleBlur} onChange={handleChange} type="text" value={reference}  /> 
                        { touched.reference && errors.reference ? <div  className="alert alert-danger">  { errors.reference} </div> : null} 
                     
                    </div>     
                    <div className="form-group"> 
                            <label className="form-label">In Favour of</label> <label className="form-label required" >*</label>
                            
                            <div>   
                            <input  id="in_favour_of" name="in_favour_of" className="form-control" onBlur={handleBlur} onChange={handleChange} type="text" value={in_favour_of}  /> 
                             { touched.in_favour_of && errors.in_favour_of ? <div  className="alert alert-danger">  { errors.in_favour_of} </div> : null}                         
                           
                            </div>
                    </div>
                     <div className="form-group">
                        <label className="form-label">Banking Details</label> 
                        <select id="bankig_details" name="bankig_details"  onBlur={handleBlur}  onChange={handlePaymentDetailes} className="form-select" >
                        <option disabled selected hidden >select banking details</option>
                           { dump.paymentProfile.map( (element,index) => ( <option value={index} > { element['name']} </option> ))}
                           
                        </select> 
                       
                    </div> 
                        
                    <div className="form-group">
                        <label className="form-label">Bank</label> 
                        <input  id="bank" name="bank" className="form-control" onBlur={handleBlur} onChange={handleChange} type="text" value={bank}  /> 
                        { touched.bank && errors.bank ? <div  className="alert alert-danger">  { errors.bank} </div> : null} 
                    </div>
                    <div className="form-group">
                        <label className="form-label">Account Details</label> 
                        <input  id="account_no" name="account_no" className="form-control" onBlur={handleBlur} onChange={handleChange} type="text" value={account_no}  /> 
                        { touched.account_no && errors.account_no ? <div  className="alert alert-danger">  { errors.account_no} </div> : null} 
                    </div>
                    <div className="form-group">
                        <label className="form-label">Branch Code</label> 
                        <input  id="branch_code" name="branch_code" className="form-control" onBlur={handleBlur} onChange={handleChange} type="text" value={branch_code}  /> 
                        { touched.branch_code && errors.branch_code ? <div  className="alert alert-danger">  { errors.branch_code} </div> : null} 
                    </div>
                    <div className="form-group">
                        <label className="form-label">In respect of Education/Debt</label> 
                        <select id="in_respect_of" name="in_respect_of"  onBlur={handleBlur}  onChange={handleChange} className="form-select" >
                        <option disabled selected hidden >select In respect of</option>
                            <option value="Education" >Education</option>
                            <option value="Debt" >Debt</option>
                            <option value="Accommodation">Accommodation</option>
                            <option value="Travelling">Travelling</option>
                            <option value="Textbooks">Textbooks</option>
                        </select> 
                        { touched.in_respect_of && errors.in_respect_of ? <div  className="alert alert-danger">  { errors.in_respect_of} </div> : null} 
                    </div>
                    <div className="form-group">
                        <label className="form-label">Account</label> 
                        <input  id="account" name="account" className="form-control" onBlur={handleBlur} onChange={handleChange} type="text" value={account}  /> 
                        { touched.account && errors.account ? <div  className="alert alert-danger">  { errors.account} </div> : null} 
                    </div>
                    <div className="form-group">
                        <label className="form-label">Amount Approved</label> 
                        <input  id="amount_approved" name="amount_approved" className="form-control" onBlur={handleBlur} onChange={handleChange} type="text" value={amount_approved}  /> 
                        { touched.amount_approved && errors.amount_approved ? <div  className="alert alert-danger">  { errors.amount_approved} </div> : null} 
                    </div>
                            
                    <div className="form-group">
                    <div>
                        <input type="radio" id="eft" name="eft_chq" onChange={handleChange}  value="EFT" checked="checked"/>
                        <label className="form-label" style={{marginRight: 10}}>EFT</label>
                        <input type="radio" id="chq" name="eft_chq" onChange={handleChange}  value="Cheque"/>
                        <label className="form-label">Cheque</label>  
                        { errors.eft_chq ? errors.eft_chq : null} 
                    </div>
                    </div>                     

                    <div className="form-group">
                        <label className="form-label">Approved1</label> 
                        <input  id="approved1" name="approved1" className="form-control" onBlur={handleBlur} onChange={handleChange} type="text" value={approved1}  /> 
                        { touched.approved1 && errors.approved1 ? <div  className="alert alert-danger">  { errors.approved1} </div> : null} 
                    </div>
                    <div className="form-group">
                        <label className="form-label">Approved2</label> 
                        <input  id="approved2" name="approved2" className="form-control" onBlur={handleBlur} onChange={handleChange} type="text" value={approved2}  /> 
                        { touched.approved2 && errors.approved2 ? <div  className="alert alert-danger">  { errors.approved2} </div> : null} 
                    </div>
                    
                    
                    <div>
                       <input id="pay" type="button" value="Save" onClick={ ()=> { if (noError(payment,err)) { savePayment(payment) }}} className="btn-success form-control btn-next" style={{marginTop: 10}}/>
                          
                    </div>  
                 </form>
             </Container  >
        )
    }
    const DisplayForm = () =>{
        switch(display){
            case 'report':
                return PaymentReport()
            case 'form':
                return PaymentForm()
                    
        }
    }
    return(
        <div> 
             <button onClick={ ()=> window.location.reload() }>Back to applicant page</button>
            <Container maxWidth="xs">
            { updated && showUpdated()}     
            </Container>
            {DisplayForm()}
        </div>
    )
    
}
export default Payment;

