import React, { useState , useEffect} from 'react';
import { Link, withRouter , useHistory} from "react-router-dom";

import {useForm, useStep } from 'react-hooks-helper'
import {Form, useFormik}  from 'formik'
import * as Yup from 'yup';
import  Container from '@material-ui/core/Container'
import FormLabel from '@material-ui/core/FormLabel'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Typography from '@material-ui/core/Typography'; 
import { makeStyles } from '@material-ui/core/styles';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetail from '@material-ui/core/AccordionDetails'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Layout from '../core/Layout';
import { sendComment,sendGeneralEmails  } from '../util';
import { TextareaAutosize, TextField } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import Checkbox from '@material-ui/core/Checkbox';
import { read } from 'xlsx';
import { Tune } from '@material-ui/icons';

const Emails = ({application}) =>{  
    const [display,setDisplay] = useState('comment')
    const [selectedField, selecField] = useState([]);
    const [selectedEmail, selecEmail] = useState([]);
    const text =  'Dear first_name  last_name\n \n\n \n \n \n Click on the link below or copy and paste it into a browser address bar \n \n url \n \n \n We thank you for your co-operation. \n Was Salaam'
    
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; 

    
    const upload = [ 'ID Document','Proof of Income','Testimonial Letter','Motivational Letter','Acceptance Letter',
                     'Result', 'Proof of Registration', 'Fees Statement','Consent Form' ]
    const validationSchema = Yup.object({
        email: Yup.string().required('Enter your Eamil'),
        comment: Yup.string().required('enter comment')
    })
    
    const {handleSubmit, handleChange, handleBlur, setValues,  values, touched, errors } = useFormik({ 
        initialValues: {
            comment:'',
            email:'',
            selectedEmails: [],
            cc:'',
            bcc:'',
            subject: '',
            field: [],
            body: '',
            acceptanceEmails: '0',
            loading: false,
            redirectToReferrer: false
        },
        validationSchema
    });
    const { comment, cc,selectedEmails, acceptanceEmails, email,re_email ,subject, body,field, loading, error, redirectToReferrer } = values;	


    const  notEmpty = (obj) => {
        
        for (var key in obj) {
            if (obj[key] == ""){
                alert(`Select File for ${key}`)
                return false;
            }
        }
        return true;
    }
    const  isError = obj => {
        for (var key in obj) {
            if (obj[key])
                return obj[key];
        }
        return false;
    }
    

    const noError = (data, err )=>{

     /*  if (notEmpty(data))  {         
            setValues({ ...values, error: `Enter Regisration Data`, success: false });
            return false 
        } */
        if (isError(err)) {
            setValues({ ...values, error: isError(err) , success: false });
            return  false       
        }
        setValues({...values, error: '' });
        return true;
        //setValues({ ...values, error: false  });

    }
    const saveComment = ()=> {
        //setValues({ ...values, error: false, loading: true });
        sendComment( comment, application['application_id'] ).then(data => {
            if (data.error) {
                alert(data.error)
            } else {
             alert('Comment Save')
            }
        });
    }
    
  
   
    
    const AddField = ()=>{
        return (
            <Container >
                {showLoading()}
                <div style={{ color: 'red', fontSize: 20 }} >
                    
                </div>
                <div className="form-group">
                    <label className="form-label">Select Document</label>
                </div>
                <div className="form-group">
                    <div>
                        <select id="decument" name="decument" onBlur={handleBlur} onChange={handleAddField} type="option" className="form-select"> 
                        <option  selected hidden >select document</option>
                        <option value="0" >ID Document</option> 
                        <option value="1" >Proof of Income</option>
                        <option value="2" >Testimonial Letter</option>  
                        <option value="3" >Motivational Letter</option>
                        <option value="4">Acceptance Letter</option>
                        <option value="5" >Result</option>
                        <option value="6">Proof of Registration</option>
                        <option value="7" >Fees Statement</option>
                        <option value="8" >Consent Form</option>                  
                        
                        </select>
                        { touched.gender && errors.gender ? <div  className="alert alert-danger"> { errors.gender } </div>: null}
                    </div>                  
                </div>
                <div>  { selectedField.length > 0 && ( <div className="alert alert-secondary" role="alert"> <ul>   {selectedField.map((item, i) => { return  <span><p> {item} <button  id={item} name={item} onClick={removeField}/></p></span>;
                                             })}
                                           </ul>
                                              </div>  )}  
                              </div>
             </Container  >
        )
    }


const SearchBar = ({ placeholder, data }) => {
	const [filteredData, setFilteredData] = useState([])
	const [wordEntered, setWordEntered] = useState("")
  
	const handleFilter = (event) => {     
     
	  const searchWord = event.target.value
	  setWordEntered(searchWord);
	  const newFilter = data.filter((value) => {		
		return value['First Name'].toLowerCase().includes(searchWord.toLowerCase()) 
	  });
  
	  if (searchWord === "") {
		setFilteredData([]);
	  } else {
		setFilteredData(newFilter);
	  }
	};
  
	const clearInput = () => {
	  setFilteredData([]);
	  setWordEntered("");
	};
	const handleSearchResult = (event) =>{		
		if (!selectedEmails.includes(event.target.name) ){            
            const newarr = [...selectedEmails];
            newarr.push(event.target.name);
            setValues({...values, selectedEmails: newarr});
        }
	}
		
      
	  
  
	return (
	  <div >
		<div className="searchInputs" >
		  <input className="form-control"
            name="search"
            id="search"
			type="text"
			placeholder={placeholder}
			value={wordEntered}
			onChange={handleFilter}
		  />
		  <div className="searchIcon">
			{filteredData.length === 0 ? (
			  <SearchIcon />
			) : (
			  <CloseIcon id="clearBtn" onClick={clearInput} />
			)}
		  </div>
		</div>
		{filteredData.length != 0 && (
		  <div className="dataResult">
			{filteredData.slice(0, 15).map((value, key) => {
			  return (
				<Link className="dataItem" name={value['Email']}  onClick={handleSearchResult}>{value['First Name']} {value['Last Name']}</Link>
				  
			  );
			})}
		  </div>
		)}
		{selectedEmails.length != 0 && (
		  <div>
              
			{selectedEmails.map((value) => {
			  return (
		
                  <div> {value} <button  id={value} name={value} onClick={removeEmail}/></div>
             
				  
			  );
			})}
           
		  </div>
		)}
	  </div>
	);
  }
    const handleAddField =(event)=>{

        if (!selectedField.includes(upload[parseInt(event.target.value)])) {   
            selecField((prevMessage) => ([
                ...prevMessage, upload[parseInt(event.target.value)]
                
            ]));
            const newarr = [...field];
            newarr.push(event.target.value);
            setValues({...values, field: newarr});
           
        }
        else{
            alert(`${upload[parseInt(event.target.value)]} is already selected`)
        }

    }

    const removeField = (event) =>{
        event.preventDefault()
        selecField(selectedField.filter(item => item !== event.target.name));
        const newarr = [...field];
        newarr.pop(event.target.value);
        setValues({...values, field: newarr});
    }
    const removeEmail = (event) =>{
        const newarr = [...selectedEmails];
        newarr.pop(event.target.name);
        setValues({...values, selectedEmails: newarr})
    }
    const EmailForm = ()=>{
        return (
            <Container>
                    <div className="form-group">
                        <label className="form-label">to</label>
                    </div>
                    <div className="form-group">
                        <Checkbox key="acceptanceEmails" id="acceptanceEmails" name="acceptanceEmails" onBlur={handleBlur} onChange={handleChange} value='1'   /> 
                        <label className="form-label"> Send to All Accepted Applicants </label>
                        { touched.email && errors.email ? <div  className="alert alert-danger"> { errors.email} </div> : null}                    
                    </div>
                    <div>
                        <label className="form-label" > Specify Applicants </label>
                        <div  className="alert alert-info"> You can search for emails using applicant's first name. You can also type emails, if more than one, separate emails by ; </div>
                        <SearchBar placeholder="search" data={application}/>
                      
                            
                    </div>
                  
                    <div className="form-group">
                        <label className="form-label">cc</label>                                            
                         <input className="form-control" id="cc" name="cc"  onBlur={handleBlur} onChange={handleChange} type="text" value={cc}  /> 
                        { touched.cc && errors.cc ? <div  className="alert alert-danger"> { errors.cc} </div> : null}                        
                    </div>
                    <div className="form-group">
                        <label className="form-label">subject</label>                                            
                         <input className="form-control" id="subject" name="subject"  onBlur={handleBlur} onChange={handleChange} type="text" value={subject}  /> 
                        { touched.subject && errors.subject ? <div  className="alert alert-danger"> { errors.subject} </div> : null}                        
                    </div>
                    <div className="form-group">
                        <label className="form-label">body</label>
                      
                        <textarea id="body" name="body" rows="15" onBlur={handleBlur} onChange={handleChange} type="text" className="form-control" value={body}/>
                        {touched.body && errors.body ? <div  className="alert alert-danger"> { errors.body } </div> : null}                 
                    </div>
                    <div> 
                        <input id="mail" type="button" value="Send" onClick={() => sendEmail() } style={{marginleft: 10}} className="btn-success form-control btn-next" style={{marginTop: 10}}/>                            
                    
                    
                    
                    </div>
            </Container>
        )
    }

  
    const showLoading = () =>
        loading && (
            <div className="alert alert-info">
                <h2>sending...</h2>
            </div>
        );

    const sendEmail = ()=> {
        window.scrollTo(0, 0);
        setValues({ ...values, error: false, loading: true });
        let ready = true
        let accpetedEmails = [] 
        let enteredEmails = []
        let cc = []
        if (application != undefined){
            application.map(obj => { 
            if ((obj.accept_decline == 1) && (re.test(String(obj['Email']).toLowerCase()))) {
                accpetedEmails.push(obj['Email'])                    
            }
            }) 
        }         
        let text = document.getElementById("search").value
        if (text !== '') {
            enteredEmails = text.replace(/\s/g, "").split(';')
        }
        text = document.getElementById("cc").value
        if (text !== '') {
            cc = text.replace(/\s/g, "").split(';')
        }
        let to = [...selectedEmails,...enteredEmails]  
        to.map(email =>{           
            if (!re.test(String(email).toLowerCase())) {
                setValues({ ...values, error: false, loading: false });
                ready = false
                alert(`entered ${email} not valid email`)
            }
        })
        cc.map(email =>{           
            if (!re.test(String(email).toLowerCase())) {
                setValues({ ...values, error: false, loading: false });
                ready = false
                alert(`CC ${email} not valid email`)
            }
        })
        
        
        if (acceptanceEmails == '1') {
            to = [...accpetedEmails]  
        }
        if (ready) {
            sendGeneralEmails( {to,cc,subject, body} ).then(data => {
                    if (data.error) {
                        setValues({ ...values, error: false, loading: false });
                        alert(data.error)
                    } else {
                    setValues({ ...values, error: false, loading: false });
                    alert(`Email sent`)
                    }
                })
         }
        }
    return(
        <div>
            <button onClick={()=> {   window.location.reload();}}> Back to Admin Page </button>
            <Container maxWidth="lg">
            <form>
               
                {EmailForm()}                 
            </form>
            </Container>
        </div>
    )
    
}
export default Emails;