import React from "react";
import MainMenu from "./Menu";



const Layout = ({  children }) => (
        <div>
             <MainMenu />    
                <div  >{children}</div>        
         </div>
);

export default Layout;
