import React, {Component} from 'react'
import {Route, Redirect } from 'react-router-dom'
import {isAuthenticated} from './index'


const PrivateRoute = ({component: Component, ...rest })=> (
    <Route {...rest} render={ props => isAuthenticated()  ? ( <Component {...props}/>) : 
    (<Redirect to= {{pathname:'/signin', satae: {from: props.location }}}/>)  }/>
)
export default PrivateRoute
